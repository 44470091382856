import React, { Component } from 'react';
import Slider from "react-slick";
import './newEquity-Considerations.css';

export default class NewEquityConsiderations extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1650,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            fade: true
          }
        }
      ]
    };
    return (
      <div className="newEquityConsiderations-container">
        <div className="newEquityConsiderations-content-container">
          <h2>Equity Considerations In Our Work</h2>
          <div className="newEquityConsiderations-carousel-slider">
            <Slider {...settings}>
              {/* Begin Individual Card Content */}
              <div className="newEquityConsiderations-card">
                <div className="newEquityConsiderations-card-content">   
                  <div className="newEquityConsiderations-card-content-container">
                    <div className="newEquityConsiderations-text">
                      <h3>Grassroots Partnerships</h3>
                      <p>We collaborate directly with youth, teachers, and communities at a grassroots level.</p>
                    </div>
                  </div>                 
                </div>
                <div className="newEquityConsiderations-card-image">
                  <img src='https://f10-image.s3.us-west-2.amazonaws.com/Test+Images/Grassroot+Partnerships.png' width="100%" alt=""/>
                </div>
              </div>
              <div className="newEquityConsiderations-card">
                <div className="newEquityConsiderations-card-content">   
                  <div className="newEquityConsiderations-card-content-container">
                    <div className="newEquityConsiderations-text">
                      <h3>Access</h3>
                      <p>We assess and remove barriers to access in our programs, research projects, and communication.</p>
                    </div>
                  </div>                 
                </div>
                <div className="newEquityConsiderations-card-image">
                  <img src='https://f10-image.s3.us-west-2.amazonaws.com/Test+Images/Access.png' width="100%" alt=""/>
                </div>
              </div>
              <div className="newEquityConsiderations-card">
                <div className="newEquityConsiderations-card-content">   
                  <div className="newEquityConsiderations-card-content-container">
                    <div className="newEquityConsiderations-text">
                      <h3>Youth Pespectives</h3>
                      <p>Youth feedback is crucial to how we develop, deliver, and evaluate our projects. We actively work to expand learning pathways centering youth curiosity, voice, and choice.</p>
                    </div>
                  </div>                 
                </div>
                <div className="newEquityConsiderations-card-image">
                  <img src='https://f10-image.s3.us-west-2.amazonaws.com/Test+Images/Youth+Perspectives.png' width="100%" alt=""/>
                </div>
              </div>
              <div className="newEquityConsiderations-card">
                <div className="newEquityConsiderations-card-content">   
                  <div className="newEquityConsiderations-card-content-container">
                    <div className="newEquityConsiderations-text">
                      <h3>Power Dynamics</h3>
                      <p>We actively work against power imbalances in our philanthropic relationships by centering community partners in decision-making.</p>
                    </div>
                  </div>                 
                </div>
                <div className="newEquityConsiderations-card-image">
                  <img src='https://f10-image.s3.us-west-2.amazonaws.com/Test+Images/Power+Dynamics.png' width="100%" alt=""/>
                </div>
              </div>  
              <div className="newEquityConsiderations-card">
                <div className="newEquityConsiderations-card-content">   
                  <div className="newEquityConsiderations-card-content-container">
                    <div className="newEquityConsiderations-text">
                      <h3>Authentic Stories</h3>
                      <p>We use data to tell stories that expose inequities and contribute to positive narratives about youth.</p>
                    </div>
                  </div>                 
                </div>
                <div className="newEquityConsiderations-card-image">
                  <img src='https://f10-image.s3.us-west-2.amazonaws.com/Test+Images/Authentic+Stories.png' width="100%" alt=""/>
                </div>
              </div> 
              {/* <div className="newEquityConsiderations-card">
                <div className="newEquityConsiderations-card-content">   
                  <div className="newEquityConsiderations-card-content-container">
                    <div className="newEquityConsiderations-text">
                      <h3>Representation</h3>
                      <p>We strive to employ and train staff, teaching artists, and mentors who represent the diverse youth we work with and who think critically about race, gender, ability, language, culture, socioeconomic status, and sexual orientation.</p>
                    </div>
                  </div>                 
                </div>
                <div className="newEquityConsiderations-card-image">
                  <img src='https://f10-image.s3.us-west-2.amazonaws.com/Test+Images/Representation.png' width="100%" alt=""/>
                </div>
              </div>            */}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}