import React, { Component } from 'react';
import './Careers-Attributes.css';

export default class CareersAttributes extends Component {
  render() {
    return(
      <div id="careers-attributes-container">
        <div class="careers-attributeOne-content-container">
          <div class="attributeOne-content-image">
            <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Careers+Page/Curious.jpg" alt="" />
          </div>
          <div class="attributeOne-content-text">
            <div class="attributeOne-content-title">
              <h3>Curious</h3>
            </div>          
            <p>We look for team members who are driven to understand learning, explore ideas in their field of interest, and push our work in new directions.  That curiosity also fuels our own learning to ensure we stay on top of new developments in our fields through constantly challenging our own growth and development.</p>
          </div>
        </div>
        <div class="careers-attributeFour-content-container">
          <div class="attributeFour-content-text">
            <div class="attributeFour-content-title">
              <h3>Collaborative</h3>
            </div>          
            <p>Bringing many voices and viewpoints together around a common goal has created a naturally collaborative environment at foundry10. Everyone has different insights to offer, and we believe that by connecting with each other we can reach a deeper understanding of learning and create the best outcomes for youth.</p>
          </div>
          <div class="attributeFour-content-image">
            <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Careers+Page/Collaborative.jpg" alt="" />
          </div>
        </div>
        <div class="careers-attributeThree-content-container">
          <div class="attributeThree-content-image">
            <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Careers+Page/Thoughtful.jpg" alt="" />
          </div>
          <div class="attributeThree-content-text">
            <div class="attributeThree-content-title">
              <h3>Thoughtful</h3>
            </div>          
            <p>We have many opportunities to do our work so we  need to be intentional and have a deep understanding of both why and how we engage with the projects we do.  As collaborators, we are thoughtful and engaged with the communities, partners, and learners that are impacted by the work we do.</p>
          </div>
        </div>
      </div>
    );
  }
}