import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {
  useQuery,
  gql
} from "@apollo/client";
import PageHeader from '../components/page-header.js';
import Grid from '@material-ui/core/Grid';
import ContentBlocks from '../components/ContentBlocks';
import StoryCard from '../components/storyCard';
import NewFooter from '../../pages-other/NewFooter.js';

const QUERY = gql`
    query NewQuery {
        entry(slug: "press-resources") {
            ... on pages_default_Entry {
              title
              slug
              id
              blurb
              contentOptions {
                ... on contentOptions_standardContent_BlockType {
                  contentTitle
                  contentContent
                  linkUrl {
                    url
                    customText
                    target
                  }
                  contentAlignment
                }
                ... on contentOptions_icp_BlockType {
                  icpTitle
                  icpContent
                    icpImage {
                        url
                    }
                    icpImageCaption
                    icpPositionOfImage
                    icpBackgroundColor
                }
                ... on contentOptions_fwi_BlockType {
                  fwiTitle
                  fwiImage {
                      url
                  }
                  fwiCaption
                  fwiSize
                }
                ... on contentOptions_emphasized_BlockType {
                  emphasizedTitle
                  emphasizedSubheadline
                  emphasizedOverline
                  emphasizedImage {
                    url
                  }
                  emphasizedImageCaption
                  emphasizedContent
                  emphasizedLink
                  emphasizedBackgroundColor
                }
                ... on contentOptions_quote_BlockType {
                      pullQuote
                      quoteSocial
                      quoteImage {
                        url
                      }
                      quoteAttribution
                }
                ... on contentOptions_quoteSlideshow_BlockType {
                  quoteSliderTitle
                  quotes {
                    __typename  
                    ... on quotes_BlockType {
                      quotesQuote
                      quotesQuoteImage {
                        url
                      }
                      quotesQuoteAttribution
                    }
                  }
                }
                ... on contentOptions_slider_BlockType {
                  sliderTitle
                  slides {
                    __typename
                    ... on slides_BlockType {
                      slideImage {
                        url
                      }
                      slideHeadline
                      slideContent
                      slideLink {
                        customText
                        url
                        target
                      }
                    }
                  }
                }
                ... on contentOptions_list_BlockType {
                  listTitle
                  list {
                    __typename  
                    ... on list_BlockType {
                      listItemHeadline
                      listItemRichContent
                      listItemImage {
                        url
                      }
                      listItemLink {
                        url
                        customText
                        target
                      }
                    }
                  }
                }    
                ... on contentOptions_accordion_BlockType {
                  accordionTitle
                  accordion {
                    __typename  
                    ... on accordion_BlockType {
                      accordionItemTitle
                      accordionItemBlurb
                      accordionItemImage {
                        url
                      }
                      accordionItemIcon {
                        url
                      }
                      accordionItemContent
                    }
                  }
                }
                ... on contentOptions_linkList_BlockType {
                  linkListTitle
                  linkListList {
                    __typename  
                    ... on linkListList_BlockType {
                      linkListTitle
                      linkUrl
                      linkListBg {
                        url
                      }
                      linkListHoverTxt
                      linkListTitle
                    }
                  }
                  linkListDisplay
                  linkListSize
                  columnCount
                }
                ... on contentOptions_video_BlockType {
                  videoTitle
                  videoLink
                  videoCaption
                  videoImage {
                    url
                  }
                }
                ... on contentOptions_featuredResource_BlockType {
                  featuredResource {
                    title 
                    ... on resources_default_Entry {
                      blurb
                      researchAsset {
                        url
                      }
                      interestAreaTeam {
                        ... on interestAreaTeam_BlockType {
                          interestAreaTeamMember {
                            title
                            url
                          }
                        }
                      }
                      researchExternalAuthors {
                        ... on researchExternalAuthors_BlockType {
                          researchExternalAuthorsAuthor
                        }
                      }
                      featuredImageCaption
                    }
                  }
                }
                ... on contentOptions_featuredPerson_BlockType {
                  featuredPerson {
                    title 
                    url
                    ... on teamMembers_default_Entry {
                      teamMembersPronouns
                      teamMemberRoles {
                        title
                      }
                      teamMemberImage {
                        url
                      }
                      teamMemberPosition
                      teamMemberBio
                    }
                  }
                }
                ... on contentOptions_featuredStory_BlockType {
                  featuredstory {
                    title 
                    url
                    author {
                      fullName
                    }
                    dateCreated @formatDateTime (format: "M j, Y")
                    ... on stories_default_Entry {
                      blurb
                      featuredImage {
                        title
                        url
                      }
                      categories {
                        title
                      }
                      themes {
                        title
                      }
                      interestAreas {
                        title
                      }
                      researchExternalAuthors {
                        ... on researchExternalAuthors_BlockType {
                          researchExternalAuthorsAuthor
                        }
                      }
                      interestAreaTeam {
                        ... on interestAreaTeam_BlockType {
                          interestAreaTeamMember {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
  }
`


const pressReleaseQuery = gql`
  query pressReleaseQuery {
    entries(section: "stories", limit: 3, relatedToCategories: [{ id: 691 }]) {
      title
      slug
      author {
        fullName
      }
      url
      postDate @formatDateTime (format: "M j, Y")
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        themes {
          title
        }
      }
    }
  }
`

const PressReleases = () => {
  const { data, loading, error } = useQuery(pressReleaseQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  console.log(data);

  return (
    data.entries.map(
      (block) => {
        
        let props = {
          block: {block}
        }
        return (
          <Grid item sm={6} md={4}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}

const PressReleaseBlock = () => {

  return (
    <section className='pressReleases'>
      <div className="container">
        <h2 className="text-xxl">Press Releases</h2>
        <Grid container spacing={4}>
          <PressReleases />
        </Grid>
        <a className="button orange" href="/stories?cat=691">View All  <i class="fas fa-arrow-right"></i></a>
      </div>

    </section>
  )
}


const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  {console.log('data?', data.entry)}
  return (
    <ContentBlocks data={data.entry} />
  );
}


export default class PressResources extends Component {
  render() {
    return(
        <section className='newsMedia'>
              <Helmet>
                  <title>foundry10 - Press Resources</title>
                  <meta
                    name="title"
                    content="foundry10 - Press Resources"
                  />
                  <meta
                    name="description"
                    content="Explore media resources for journalists covering foundry10. Get the information you need to tell our story effectively."
                  />
                  <meta property="og:title" content="foundry10 - Press Resources" />
                  <meta
                    property="og:description"
                    content="Explore media resources for journalists covering foundry10. Get the information you need to tell our story effectively."
                  />
                  <meta property="og:type" content="website" />
                  <meta property="og:url" content="https://www.foundry10.org/press-resources" />
                  <link rel="canonical" href="https://www.foundry10.org/press-resources" />
                  {/* Structured Data Script Tag */}
                  <script type="application/ld+json">
                  {`
                    {
                      "@context": "https://schema.org",
                      "@type": "WebPage",
                      "name": "foundry10 - Press Resources",
                      "description": "Explore media resources for journalists covering foundry10. Get the information you need to tell our story effectively.",
                      "url": "https://www.foundry10.org/press-resources"
                    }
                  `}
                  </script>
            </Helmet>
            <PageHeader slug={this.props.slug} />
            <InlineContent slug='press-resources'/>
            <PressReleaseBlock />
            <NewFooter />
        </section>
      
    );
  }
}

