import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  useQuery,
  gql
} from "@apollo/client";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

const QUERY = gql`
      query footerQuery {
        footer: globalSet(handle: ["footer"]) {
          ... on footer_GlobalSet {
            copyrightInformation @markdown
            email
            phoneNumber
            address {
              addressLine
            }
            socialMedia {
              ...on socialMedia_BlockType {
                networkName
                networkLink
                icon {
                  title
                  url
                }
              }
            }
          }
        }
      }
`

const SQUERY = gql`
  query footerQuery {
    footer: globalSet(handle: ["footer"]) {
      ... on footer_GlobalSet {
        copyrightInformation @markdown
        address {
          addressLine
        }
        socialMedia {
          ...on socialMedia_BlockType {
            networkName
            networkLink
            icon
          }
        }
      }
    }
  }
`


const Address = () => {
  const { data, loading, error } = useQuery(QUERY);

  if (loading) {
    return <div>loading</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    data.footer.address.map(({ addressLine }, index) => (
      <p key={index}>
        {addressLine} 
      </p>
    )
  ));
}

const SocialMedia = () => {
  const { data, loading, error } = useQuery(QUERY);
  if (loading) {
    return <div>loading</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
   data.footer.socialMedia.map((socialMedia, index) => {
    const icon = socialMedia.icon[0]
      return (
        <p key={index}>
          <a href={socialMedia.networkLink} targe="_blank">
            <img src={icon.url} alt={socialMedia.networkName} />
            {socialMedia.networkName}
          </a>
        </p>
      )
    }
  ));
}

const Contact = () => {
  const { data, loading, error } = useQuery(QUERY);
  if (loading) return "Loading...";
  if (error) return <pre>{error.message}</pre>
  
  return (
    <div className="footer-office-contact">
      <h3>Contact</h3>
      <p>
        {data.footer.phoneNumber}
      </p>
      <p>
        {data.footer.email}
      </p>
      <p>M-Th 10am-5pm</p>
    </div>
  )
}

const Copyright = () => {
  const { data, loading, error } = useQuery(QUERY);
  if (loading) return "Loading...";
  if (error) return <pre>{error.message}</pre>
  
  return (
    <div className='copyright'
        dangerouslySetInnerHTML={{
          __html: data.footer.copyrightInformation,
        }}
    ></div>
  )
}

export default class GlobalFooter extends Component {
  
  render() {
    return (
      <div className="globalFooter">
        <div className="container">
            <Grid container spacing={40}>

                <Grid item md={3} xs={12} className="footer-contact">
                <svg width="200" height="40"
                    xmlns="http://www.w3.org/2000/svg">
                    <image href="https://f10-image.s3.us-west-2.amazonaws.com/foundry10+vector+logo+svg.svg" height="40" width="200"/>
                </svg>
                    <div className="footer-connect-links">
                    <SocialMedia />
                    </div>
                </Grid>
                <Grid item md={2} xs={12} className="footer-office">
                    <h3>Office</h3>
                    <Address />
                </Grid>
                <Grid item md={2} xs={12} className="footer-contact">
                <Contact />
                </Grid>
                
                <Grid item md={5} xs={12} className="footer-landAcknowledgement">
                    <h3>Land Acknowledgement</h3>
                    <p>We acknowledge that our office is located on the traditional territories of the <a href="https://www.duwamishtribe.org" target="_blank" rel="noopener noreferrer">Duwamish</a>, <a href="https://suquamish.nsn.us" target="_blank" rel="noopener noreferrer">Suquamish</a>, and Coast Salish peoples of past, present, and future. We honor the tribal Elders of the Pacific Northwest and their descendants with gratitude for their stewardship of the water, the land, and its inhabitants. We recognize that this acknowledgement is not a substitute for building authentic relationships with Indigenous communities.</p>
                
                </Grid>
            
            </Grid>
        </div>
      <Copyright />
      </div>
    );
  }
}