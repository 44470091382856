import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#F6F6F6',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      className: 'test',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
}))(MuiExpansionPanelDetails);

class Accordion extends React.Component {
  state = {
    expanded: 'panel0',
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { expanded } = this.state;
    return (
      <section className="accordion">
        <div className='container'>
        {this.props.block.accordionTitle && (
          <h2 className='text-xl_bold'>{this.props.block.accordionTitle}</h2>
        )}
        {this.props.block.accordion.map((key,panel) => (
          <ExpansionPanel
            className='aPanel faq-page'
            square
            expanded={expanded === 'panel'+ panel }
            onChange={this.handleChange('panel' + panel)}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} expanded='exPanel' className="accPanel">
              {key.accordionItemIcon[0] && (
                <Avatar alt={key.accordionItemTitle} src={key.accordionItemIcon[0].url} style={{ margin: '0 20px 0 0', height: '68px', width: '68px' }} />
              )}
              <div className='faq-section'>
                <div className='faq-img'>
                  <img src={key.accordionItemImage[0].url} alt='' />
                </div>
                <div className='faq-content'>
                  <h3 className="text-medium_bold">
                    {key.accordionItemTitle}
                  </h3>
                  <p>{key.accordionItemBlurb}</p>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className='accContent'>
                <Grid container spacing={40}>
                  {/* {key.accordionItemImage[0] && (
                    <Grid item md={4}>
                      <img src={key.accordionItemImage[0].url} alt='Accordion Image' />
                    </Grid>
                  )} */}
                  {key.accordionItemImage[0]
                  ? <Grid item md={12}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: key.accordionItemContent,
                        }}
                      ></div>
                    </Grid>
                  : <Grid item md={12}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: key.accordionItemContent,
                        }}
                      ></div>
                    </Grid>
                  }

                </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
        </div>
      </section>
    );
  }
}
export default Accordion