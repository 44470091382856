import React from "react";
const NewsletterSignup = ({ block }) => {
  return (
    <section className="newsletterSignup">
      <div className="container">
        <div className="newsletter-title text-xl_bold text-center">
          <span>Don't Miss the Latest Updates!</span>
        </div>
        <a
          className="signup-link text-medium"
          href="https://foundry10.us3.list-manage.com/subscribe?u=61e3e87d0bc76df778693653e&id=65c221485b"
          target="_blank"
          rel="noopener noreferrer"
        >
          Subscribe to Our Monthly Newsletter
        </a>
      </div>
    </section>
  );
};
export default NewsletterSignup;