import React, { Component } from 'react';
import './homeRedesign2023-IAs.css';

export default class HomeInterestAreas extends Component {
  render() {
    return(
      <div id="homeRedesign2023-IAs-container">
        <div className="homeRedesign2023-IAs-content-container">
            <div className="homeRedesign2023-IAs-header-container">
                <h2>Interest Areas</h2>
                <p>To maximize the impact of our work, foundry10 focuses our research, educational programming, and philanthropy around four critical areas of contemporary research and practice related to youth learning. A cross-functional "Interest Area team" collaboratively leads our organizational efforts in the following areas.</p>
            </div>
            <div className="homeRedesign2023-IAs-teams-container">
                <a href="/interest-areas/career-connected-learning-and-life-skills">
                    <div className="homeRedesign2023-IAs-eachTeam">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/508x508_Career-Connected-Learning-Thumbnail.jpg" alt="" />
                        <div className="homeRedesign2023-IAs-team-card-text">
                            <h3 className="homeRedesign2023-IAs-team-card-title">Career-Connected Learning and Life Skills</h3>
                            <p className="homeRedesign2023-IAs-team-card-blurb">We help young people find their purpose and prepare them to thrive in their future jobs and adult lives.</p>
                            <p className="homeRedesign2023-IAs-team-card-arrow">Learn More →</p>
                        </div>
                    </div>
                </a>
                <a href="/interest-areas/creativity-design-and-play">
                    <div className="homeRedesign2023-IAs-eachTeam">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/CDP-Thumbnail_Homepage.jpg" alt="" />
                        <div className="homeRedesign2023-IAs-team-card-text">
                            <h3 className="homeRedesign2023-IAs-team-card-title">Creativity, Design, and Play</h3>
                            <p className="homeRedesign2023-IAs-team-card-blurb">We seek to ensure young people have the space and tools they need to innovate, build creative abilities, and learn through play—both inside and outside of classrooms.</p>
                            <p className="homeRedesign2023-IAs-team-card-arrow">Learn More →</p>
                        </div>
                    </div>
                </a>
                <a href="/interest-areas/health-and-well-being">
                    <div className="homeRedesign2023-IAs-eachTeam">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/508x508_HWB_Thumbnail.jpg" alt="" />
                        <div className="homeRedesign2023-IAs-team-card-text">
                            <h3 className="homeRedesign2023-IAs-team-card-title">Health and Well-Being</h3>
                            <p className="homeRedesign2023-IAs-team-card-blurb">We identify and address gaps in student health and well-being, increase access to health services, and ensure students have what they need to grow into healthy and well adults.</p>
                            <p className="homeRedesign2023-IAs-team-card-arrow">Learn More →</p>
                        </div>
                    </div>
                </a>
                <a href="/interest-areas/technology-media-and-information-literacy">
                    <div className="homeRedesign2023-IAs-eachTeam">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/508x508_TMIL-Thumbnail.jpg" alt="" />
                        <div className="homeRedesign2023-IAs-team-card-text">
                            <h3 className="homeRedesign2023-IAs-team-card-title">Technology, Media, and Information Literacy</h3>
                            <p className="homeRedesign2023-IAs-team-card-blurb">We equip young people with the tools and skills to responsibly build and work with emerging technologies, creatively develop digital media, and critically consume information online.</p>
                            <p className="homeRedesign2023-IAs-team-card-arrow">Learn More →</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
      </div>
    );
  }
}