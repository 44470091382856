import React, { Component } from "react";
import { useQuery, gql } from "@apollo/client";
import { withRouter } from "react-router-dom";

//Get page header based on ID
const pageQuery = gql`
  query pageHeaderQuery($slug: [String]) {
    entries(slug: $slug) {
      title
      ... on resources_default_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
    }
  }
`;

const Hero = withRouter(({ slug, history }) => {
  const { data, loading, error } = useQuery(pageQuery, {
    variables: { slug: slug },
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
  if (!data.entries || data.entries.length === 0) {
    history.push("/not-found");
    return null;
  }
  let bgImage = null;
  // console.log('hero data');
  // console.log(slug);
  const pageHeader = data.entries[0];
  //console.log(pageHeader.featuredImage[0].url);
  const blurb = pageHeader.blurb;
  // console.log(blurb);
  //const bgImage = pageHeader.featuredImage[0];
  //const bgImage = pageHeader.featuredImage[0];
  if (pageHeader.featuredImage.length > 0) {
    //console.log('image here')
    bgImage = pageHeader.featuredImage[0].url;
  } else {
    bgImage = "";
  }

  let myStyle = {};

  let title;
  if (pageHeader.customTitle) {
    title = pageHeader.customTitle;
  } else {
    title = pageHeader.title;
  }
  const bg = bgImage ? "bgImage" : "orange";
  const withBlurb = blurb ? "withBlurb" : "";
  const classes = `${bg} ${withBlurb}`;

  if (!!bgImage) {
    //console.log('getting here');
    myStyle = {
      backgroundImage: `url(${bgImage})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
  } else {
    myStyle = {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
  }

  //console.log( myStyle );

  //let bgImage = pageHeader.featuredImage[0].url;
  //console.log(bgImage);
  return (
    <div id="heroContent" className={classes} style={myStyle}>
      <Blurb slug={title} position={bgImage ? "absolute": "relative"} />
    </div>
  );
});

function Blurb({ slug, position = "absolute" }) {
  const blurb = slug;

  if (blurb) {
    return (
      <div
        className={`blurb ${position}`}
      >
        <h1 className="text-large">{blurb}</h1>
      </div>
    );
  } else {
    return "";
  }
}

export default class ResourceHeader extends Component {
  render() {
    return (
      <section id="hero">
        <Hero slug={this.props.slug} />
        <div className="container">
          {this.props.blurb && <Blurb slug={this.props.slug} />}
        </div>
      </section>
    );
  }
}
