import React, { Component } from 'react';
import './annualReport2021Sharing-Web.css';

export default class AnnualReportSharingWeb extends Component {
  render() {
    return(
      <div id="annualReport2021sharing-web-container">
        <div className="annualReport2021sharing-web-content-container">
                <div className="annualReport2021sharing-web-banner-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/web+bar-8.png" alt="web highlights" />
                    <h2>Web Highlights</h2>
                </div>
            <div className="annualReport2021sharing-web-highlight">
                <h3>
                The Ultimate Guide to Underwater Work 
                </h3>
                <a href="https://www.foundry10.org/marine-science-careers" target="_blank" rel="noopener noreferrer">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/underwater+work-100.jpg"  alt="ultimate guide to underwater work"/>
                </a>
                <h4>
                This interactive resource on our site showcases over fascinating 20 interviews with ocean professionals meant to educate and inspire youth who are interested in ocean careers.
                </h4>
            </div>
            <div className="annualReport2021sharing-web-highlight">
                <h3>
                Equity Page 
                </h3>
                <a href="https://www.foundry10.org/equity" target="_blank" rel="noopener noreferrer">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/equity%2Bpage-100-cropped.jpg"  alt="equity page"/>
                </a>
                <h4>
                During the pandemic, we built a dedicated page to help people understand our action-focused approach to equity. You can find out more about it on our Equity page.
                </h4>
            </div>
        </div>
      </div>
    );
  }
}

