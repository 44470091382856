import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class MelissaMarquez extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Marine Biology</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Marine Biology"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in marine biology. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Marine Biology" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in marine biology. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/marine-biology" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/marine-biology" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Marine Biology",
                "description": "Explore an exciting marine science career in marine biology. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/marine-biology"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h1>Marine Biology</h1>
              <h2>Meet Melissa</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Melissa+Marquez.jpg" alt="melissa marquez"/>
                </div>
              </div>
              <p>Melissa Cristina Márquez is a Latina marine biologist and wildlife educator with a BA (Hons) in Marine Ecology and Conservation degree from New College of Florida, USA and an MSc in Marine Biology from Victoria University of Wellington, NZ. Márquez is a freelance environmental writer for Forbes and founder of The Fins United Initiative (TFUI; www.finsunited.co.nz), a program that brings attention to the unusual and diverse sharks (and their relatives) of the world, the diverse scientists who study them, and the threats these animals face. She is a Scholastic author, a TEDx speaker, podcast host, and a TV presenter. Márquez is currently doing her PhD at Curtin University, studying human-wildlife conflict and shark habitat use.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW */}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Melissa</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your job title? Do you feel like that fully encapsulates all that you do?</h2>
                <p>Well, currently my job is my PhD! Specifically, I would be a “PhD candidate.” But I don’t feel like it doesn’t quite covers all the hats I wear! I’m a shark scientist. I’m a science communicator and wildlife educator. I’m a TV presenter. I’m a freelance writer. I’m a podcast host. I’m a children’s book author. As you can see… I do a lot of things to keep myself busy.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Was there a specific moment that you knew this was the career for you?</h2>
                <p>I’ve always wanted to become a marine biologist, but it wasn’t until I watched Shark Week that I really began to focus on sharks in general. I even remember the exact moment! We had just moved to the USA from Mexico and I was in my room watching television (so I would stay out of the way) and I flipped to the Discovery Channel and saw a great white shark breaching out of the water and into the air to try and catch a seal. My jaw practically hit the floor and I was like, “WOAH. That is SUCH a cool animal! I want to know more.” I’ve been hooked on sharks ever since.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>How has the field, topic, and industry changed in the past 5 years? What’s next?</h2>
                <p>I’m rather new to the shark field, and already I’m seeing people challenge the “status quo” of what used to be accepted and no longer is. I know people before me went to their fish biology classes being the only girl or only BIPOC (black, indigenous, person of color) and I started seeing that change a little when I was in school. I’m optimistic it will continue to change as we strive for a more diverse and inclusive field.</p>
                <p>I hope we see more BIPOCs represented in the media in the coming years when it comes to any scientific field (like shark science). We exist, we contribute, we matter. Representation is so important and I am a big believer of “you can’t be what you can’t see.”</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to someone starting out on this career path?</h2>
                <p>Those who are in this field aren’t in it for the fame or money – we genuinely love and care for sharks. Your passion for these animals will help buoy you on days that this career path gets rough… and it will get rough… so don’t lose that. Make sure to follow shark scientists on social media (there are plenty of us out there) to learn about new shark science that has come out, to learn cool facts about sharks, and to eventually network with them! Converse with us- we don’t bite, promise.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are your favorite things to do in your free time? Hobbies and interests?</h2>
                <p>I love going out to the beach and go snorkeling or diving with my friends. I am also always down for going on a hike or to go camping! If I’m not doing any of the above, I will probably be snuggled up with a good book somewhere, writing, or dancing to music in the kitchen while I whip up some food.</p>
              </div>
              {/* MARINE BIOLOGY */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Marine Biology?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Melissa's responses? <a href="https://drive.google.com/file/d/1mUFogFKgd60bTWYNXsx2R9Xh1N51Ivuk/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.ted.com/talks/melissa_marquez_what_sharks_taught_me_about_being_a_scientist" target="_blank" rel="noopener noreferrer">Learn about what sharks taught Melissa about being a scientist by listening to her TEDx talk.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.yourfreecareertest.com/marine-biologist/" target="_blank" rel="noopener noreferrer">Read more information on what marine biologists do and watch some video resources from this page.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Learn about <a href="https://www.youtube.com/watch?v=3Du2-SUSd4c" target="_blank" rel="noopener noreferrer">starting out as a marine biologist</a>.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.youtube.com/channel/UCP_Znx2tKDKi3TrqU0bAjyg" target="_blank" rel="noopener noreferrer">Check out this marine biology youtube channel.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="http://www.environmentalscience.org/career/marine-biologist" target="_blank" rel="noopener noreferrer">Find general information about job descriptions, salary, programs from this webpage.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://marinebio.life/home/podcast-ocean-science-conservation-careers/" target="_blank" rel="noopener noreferrer">Listen to this podcast about ocean conservation careers</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.youtube.com/user/seadocsociety" target="_blank" rel="noopener noreferrer">Learn cool things about sea creatures and find lecture videos about marine science at this channel.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}