import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {
  useQuery,
  gql
} from "@apollo/client";
import PageHeader from '../../../components/page-header.js';
import Grid from '@material-ui/core/Grid';
import StoryCard from '../../../components/storyCard.js';
import DTETopContentBlocks from './dteTopContentBlock.js';
import DTEBottomContentBlocks from './dteBottomContentBlock.js';
import NewFooter from '../../../../pages-other/NewFooter.js';
import './digitalTechnologiesEducationLab.css';

const QUERY = gql`
    query NewQuery {
        entry(slug: "digital-technologies-and-education-lab") {
            ... on pages_default_Entry {
              title
              slug
              id
              blurb
              contentOptions {
                ... on contentOptions_standardContent_BlockType {
                  contentTitle
                  contentContent
                  linkUrl {
                    url
                    customText
                    target
                  }
                  contentAlignment
                }
                ... on contentOptions_icp_BlockType {
                  icpTitle
                  icpContent
                    icpImage {
                        url
                    }
                    icpImageCaption
                    icpPositionOfImage
                    icpBackgroundColor
                }
                ... on contentOptions_fwi_BlockType {
                  fwiTitle
                  fwiImage {
                      url
                  }
                  fwiCaption
                  fwiSize
                }
                ... on contentOptions_emphasized_BlockType {
                  emphasizedTitle
                  emphasizedSubheadline
                  emphasizedOverline
                  emphasizedImage {
                    url
                  }
                  emphasizedImageCaption
                  emphasizedContent
                  emphasizedLink
                  emphasizedBackgroundColor
                }
                ... on contentOptions_quote_BlockType {
                      pullQuote
                      quoteSocial
                      quoteImage {
                        url
                      }
                      quoteAttribution
                }
                ... on contentOptions_quoteSlideshow_BlockType {
                  quoteSliderTitle
                  quotes {
                    __typename  
                    ... on quotes_BlockType {
                      quotesQuote
                      quotesQuoteImage {
                        url
                      }
                      quotesQuoteAttribution
                    }
                  }
                }
                ... on contentOptions_slider_BlockType {
                  sliderTitle
                  slides {
                    __typename
                    ... on slides_BlockType {
                      slideImage {
                        url
                      }
                      slideHeadline
                      slideContent
                      slideLink {
                        customText
                        url
                        target
                      }
                    }
                  }
                }
                ... on contentOptions_list_BlockType {
                  listTitle
                  list {
                    __typename  
                    ... on list_BlockType {
                      listItemHeadline
                      listItemRichContent
                      listItemImage {
                        url
                      }
                      listItemLink {
                        url
                        customText
                        target
                      }
                    }
                  }
                }    
                ... on contentOptions_accordion_BlockType {
                  accordionTitle
                  accordion {
                    __typename  
                    ... on accordion_BlockType {
                      accordionItemTitle
                      accordionItemBlurb
                      accordionItemImage {
                        url
                      }
                      accordionItemIcon {
                        url
                      }
                      accordionItemContent
                    }
                  }
                }
                ... on contentOptions_linkList_BlockType {
                  linkListTitle
                  linkListList {
                    __typename  
                    ... on linkListList_BlockType {
                      linkListTitle
                      linkUrl
                      linkListBg {
                        url
                      }
                      linkListHoverTxt
                      linkListTitle
                    }
                  }
                  linkListDisplay
                  linkListSize
                  columnCount
                }
                ... on contentOptions_video_BlockType {
                  videoTitle
                  videoLink
                  videoCaption
                  videoImage {
                    url
                  }
                }
                ... on contentOptions_featuredResource_BlockType {
                  featuredResource {
                    title 
                    ... on resources_default_Entry {
                      blurb
                      researchAsset {
                        url
                      }
                      interestAreaTeam {
                        ... on interestAreaTeam_BlockType {
                          interestAreaTeamMember {
                            title
                            url
                          }
                        }
                      }
                      researchExternalAuthors {
                        ... on researchExternalAuthors_BlockType {
                          researchExternalAuthorsAuthor
                        }
                      }
                      featuredImageCaption
                    }
                  }
                }
                ... on contentOptions_featuredPerson_BlockType {
                  featuredPerson {
                    title 
                    url
                    ... on teamMembers_default_Entry {
                      teamMembersPronouns
                      teamMemberRoles {
                        title
                      }
                      teamMemberImage {
                        url
                      }
                      teamMemberPosition
                      teamMemberBio
                    }
                  }
                }
                ... on contentOptions_featuredStory_BlockType {
                  featuredstory {
                    title 
                    url
                    author {
                      fullName
                    }
                    dateCreated @formatDateTime (format: "M j, Y")
                    ... on stories_default_Entry {
                      blurb
                      featuredImage {
                        title
                        url
                      }
                      categories {
                        title
                      }
                      themes {
                        title
                      }
                      interestAreas {
                        title
                      }
                      researchExternalAuthors {
                        ... on researchExternalAuthors_BlockType {
                          researchExternalAuthorsAuthor
                        }
                      }
                      interestAreaTeam {
                        ... on interestAreaTeam_BlockType {
                          interestAreaTeamMember {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
  }
`

const dteStoriesQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 3, relatedToCategories: [{ id: 70802 }]) {
        title
        slug
        url
        author {
          fullName
        }
        postDate @formatDateTime (format: "M j, Y")
        ... on stories_default_Entry {
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          blurb
          featuredImage {
            url
          }  
          categories {
            title
          }
        }
      }
    }
`

const RecentStoriesForDTECards = () => {
  const { data, loading, error } = useQuery(dteStoriesQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

// console.log('Recent Stories for Teens Card Data', data);

return (
  data.entries.map(
    (block) => {
      
      let props = {
        block: {block}
      }
      return (
        <Grid item xs={12} sm={4}>
          <StoryCard {...props} />
        </Grid>
      )
    }
  )
)
}

const RecentStoriesForDTEBlock = () => {

  return (
    <section className='dteCraftStories'>
        <div id="dte-craftStories-container">
        <div className="dte-craftStories-content-container">
          <h2 className="dte-craftStories-title"><span>Latest Stories</span></h2>
        <section className='dteCraftHighlightCards'>
            <section className='dteCraftStories'>
              <div className="container">
                <Grid container spacing={4}>
                  <RecentStoriesForDTECards />
                </Grid>
              </div>
           </section>
        </section>   
        <div className="dte-craftStories-bottomLink">
              <a href='/stories?cat=70802' className="dte-craftStories-content-bottomLink">Explore more stories<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
        </div>
        </div>
    </section>
  )
}

const DTETopInlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  return (
      <DTETopContentBlocks data={data.entry} />
  );
}

const DTEBottomInlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  return (
    <DTEBottomContentBlocks data={data.entry} />
  );
}


export default class DTECraft2 extends Component {
  render() {
    return(
        <section>
            <Helmet>
              <title>foundry10 Digital Technologies & Education Research Lab</title>
              <meta
                name="title"
                content="foundry10 Digital Technologies & Education Research Lab"
              />
              <meta
                name="description"
                content="Explore how digital technologies transform education and everyday life for young people. Learn how youth adapt to these changes in the classroom and beyond."
              />
              <meta property="og:title" content="foundry10 Digital Technologies & Education Research Lab" />
              <meta
                property="og:description"
                content="Explore how digital technologies transform education and everyday life for young people. Learn how youth adapt to these changes in the classroom and beyond."
              />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://www.foundry10.org/digital-technologies-and-education-lab" />
              <link rel="canonical" href="https://www.foundry10.org/digital-technologies-and-education-lab" />
              {/* Structured Data Script Tag */}
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@type": "AboutPage",
                    "name": "foundry10 Digital Technologies & Education Research Lab",
                    "description": "Explore how digital technologies transform education and everyday life for young people. Learn how youth adapt to these changes in the classroom and beyond.",
                    "url": "https://www.foundry10.org/digital-technologies-and-education-lab"
                  }
            `   }
              </script>
            </Helmet>
            <PageHeader slug={this.props.slug} />
            <DTETopInlineContent slug='digital-technologies-and-education-lab' />
            <RecentStoriesForDTEBlock />
            <DTEBottomInlineContent slug='digital-technologies-and-education-lab' />
            <NewFooter />
        </section>
      
    );
  }
}