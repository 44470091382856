import React from "react";
import ImageContent from "../../../components/blocks/ImageContent";
import LinkList from "../../../components/blocks/LinkList";
import Emphasized from "../../../components/blocks/Emphasized";
import List from "../../../components/blocks/List";

const CDLBottomInlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
        {block.__typename === "contentOptions_icp_BlockType" && (
        <ImageContent block={block} />
        )}
      {block.__typename === "contentOptions_linkList_BlockType" && (
        <LinkList block={block} />
      )}
      {block.__typename === "contentOptions_emphasized_BlockType" && (
        <Emphasized block={block} />
      )}
      {block.__typename === "contentOptions_list_BlockType" && (
        <List block={block} />
      )}
    </React.Fragment>
  ));
};

const CDLBottomContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <CDLBottomInlineContent entry={data} />
    </div>
  );
};

export default CDLBottomContentBlocks;
