import React, { Component } from 'react';
import './educatorResources-Publications.css';

export default class EducatorResourcesPublications extends Component {
  render() {
    return(
      <div id="educatorResources-publications-container">
        <div className="educatorResources-publications-content-container">
          <h2 className="educatorResources-publications-title"><span>Featured Publications & Resources</span></h2>
          <div className="educatorResources-publications-card-container">
            <div className="educatorResources-publications-grid-container">
              <div className="educatorResources-publications-image-container">
                <img src="https://f10-resources.s3.us-west-2.amazonaws.com/Resources+for+Educators/Classroom+Drama+Activities+for+K5+Teachers.png" alt="animation of student with hand in the air"/>
              </div>
              <div className="educatorResources-publications-info-container"> 
                <div className="educatorResources-publications-tags">
                  <div className="educatorResources-publications-hash">
                    <div className="educatorHash-teal">GUIDE</div>
                    {/* <div className="educatorHash-hash">#Drama</div> */}
                  </div>
                </div>
                <div className="educatorResources-publications-text">
                  <h2>Classroom Drama Activities for K-5 Teachers</h2>
                  <p>This resource provides a variety of activities K-5 teachers can use to add drama and play to the classroom and support students' social and emotional learning.</p>
                </div>
                <div className="educatorResources-publications-link">
                  {/* <p>Dec 7th, 2021</p> */}
                  <a href='https://www.foundry10.org/resources/classroom-drama-activities-for-k-5-teachers' className="educatorResources-publications-content-link" aria-label="View the Guide"><i class="fa-solid fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div className="educatorResources-publications-card-container">
            <div className="educatorResources-publications-grid-container">
              <div className="educatorResources-publications-image-container">
                <img src="https://f10-resources.s3.us-west-2.amazonaws.com/Resources+for+Educators/Teach-with-Portal-2-Thumbnail-Resources-for-Educators.jpg" alt="kid playing portal 2 on computer"/>
              </div>
              <div className="educatorResources-publications-info-container"> 
                <div className="educatorResources-publications-tags">
                  <div className="educatorResources-publications-hash">
                    <div className="educatorHash-teal">LESSON PLANS</div>
                    {/* <div className="educatorHash-hash">#Drama</div> */}
                  </div>
                </div>
                <div className="educatorResources-publications-text">
                  <h2>Teach with Portal 2</h2>
                  <p>Explore creative lesson plans for a broad range of grade levels, written by educators and aligned with Common Core and Next Generation Science Standards.</p>
                </div>
                <div className="educatorResources-publications-link">
                  {/* <p>Dec 7th, 2021</p> */}
                  <a href='https://www.foundry10.org/resources/portal2' className="educatorResources-publications-content-link" aria-label="View the Lesson Plan"><i class="fa-solid fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div className="educatorResources-publications-footer-container">
            <a href="https://www.foundry10.org/resources?cat=10290">
              Explore all resources and publications for educators
              <i class="fa-solid fa-arrow-right-long"></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}