import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class BethelleeHerrmann extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Oceanography</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Oceanography"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in oceanography. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Oceanography" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in oceanography. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/oceanography" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/oceanography" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Oceanography",
                "description": "Explore an exciting marine science career in oceanography. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/oceanography"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h1>Oceanography</h1>
              <h2>Meet BethElLee</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/BethElLee+Herrmann.jpg" alt="bethellee herrmann"/>
                </div>
              </div>
              <p>BethElLee Herrmann is a research scientist at the University of Washington School of Oceanography, where she has worked for several years since obtaining her Bachelor of Science in Biological Oceanography. Her research interests focus on monitoring the health of the Salish Sea through the exploration of trophic and climate-driven dynamics and how these systems drive regime shifts and adaptations within marine communities. In addition to her research on jellyfish in seasonally hypoxic environments, she is an expert zooplankton taxonomist for various projects, including one of the largest in the Pacific Northwest, the Salish Sea Marine Survival Project, which focuses on answering the questions underlying the steep decline of endangered anadromous salmon. She has published her research in peer-reviewed journals and continues to submit reports and publications to further scientific and public awareness. When not conducting research BethElLee devotes her energies into her family, art, and numerous outdoor activities.</p>
            </div>
            <div class="marineCareers-header-title-container">
              <h2>Meet Marisa</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Marisa+Gedney.jpg" alt="marisa gedney"/>
                </div>
              </div>
              <p>As a bit of background, Marisa Gedney is actually a junior officer for the NOAA Commissioned Officer Corps. So while I am not strictly a scientist committed to one research project, most of my work is done is support of NOAA's mission. Which means I can take on a variety of different tasks, from leadership positions on research ships, to collecting data at remote field sites, and even training others in emergency response roles. Currently I am stationed at the South Pole, last year I was in American Samoa, and prior to that I spent a few years as part of the crew of NOAA Ship Ronald H Brown.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW BETH*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with BethElLee</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your job title? Do you feel like that fully encapsulates all that you do?</h2>
                <p>I’m an oceanographic research scientist, officially titled Zooplankton Ecologist/Biological Oceanographer, at the University of Washington School of Oceanography. By no means does this tile describe all I do, and I find this to be the norm in ocean sciences. It’s a very interdisciplinary field that often pushes the boundaries of the STEM/STEAM education. While the bulk of my research focuses on the identification of species-specific microzooplankton in the Salish Sea, I perform anything from chemical/biological assessments to engineering prototype equipment for research cruises and I can’t forget the mathematically/statistically rich environment of data analyses, presentations of research, and finally paper composition/publications. I can confidently say, little of my education has been wasted. In fact, I always feel I need more!</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your typical workflow as a researcher.</h2>
                <p>Many of my projects focus on gathering long-term datasets from various monitoring efforts throughout the Salish Sea, which look at anything from salmon survival to the overall health of each unique ecosystem affected by hypoxia and ocean acidification.</p>
                <p>A typical workflow starts with the collection of zooplankton in net tows, alongside environmental data, via cruises. Some cruises go all-year-round, with the help of numerous scientists and tribal stewards. The cruises that I have personally been a part of are typically throughout the summer months, each about 7-10 days long and from 12-14-hour days with 8-hrs off – then back to work. It’s the most physically taxing part of my job. Zooplankton tows are especially brutal, as we need to tow nets during daylight and dusk, to capture the vertical taxis of key species.</p>
                <p>Following the processing comes the data QA/QC and querying – a process that can become increasingly tedious. It’s at this time the heart of our research starts beating…analysis, interpretation, presentation, and publication. By the time I get here, all the work I’ve accomplished becomes intimately a part of me. It’s often exciting but can also be emotionally draining. The work my team and I do is meant to illuminate the ongoing problems within our environment in hopes of finding solutions to present to the public and ultimately to policy makers. I look forward to this element because it means all the hours of physical and mental labor haven’t gone in vain.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What led you to this career path?</h2>
                <p>I began my higher academic pursuits much later in life. I started out as a professional artist and designer, something highly encouraged by my grandparents, and approached university thinking that’s where I’d end up – though I had always had a heart for the oceans.</p>
                <p>After their tragic loss due to an environmental issue, I realized how fragile we are as a species…how fragile this whole planet is. This pushed me to dive into the sciences, and after taking my first geology class, I was hooked. While I love art expression and will always seek creativity in most of my pursuits, my passion is this planet and the elements and forces which give it life. I can never have new moments with my grandparents, but I can honor them with the legacy of my research and how I live from here on out.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is the biggest risk that you’ve taken along your career path?</h2>
                <p>I think going to university for the first time at 34 years old was a risk. I jumped between three other institutions before transferring with an ASc to the UW. I was required to jump through so many hoops, one of which was working full-time for a year while attending college. I had a weekend management job for 32hrs/wk and filled in the rest of the time volunteering at a local salmon hatchery. All the while, I had to maintain full-time academics with good grades to keep my scholarships. After a year, I applied to the UW and was denied for having missed 2 credits of a foreign language in high school (way, way back). Good thing I had an awesome physics instructor who pushed me to write the university back directly and give them three reasons why their decision needed to be rescinded, along with my three resolutions to the problem. Three days later, I was accepted.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>If you could be any marine animal, what would you be?</h2>
                <p>If I could pick any marine animal to live out my days, I’d be a jellyfish (Cyanea lamarckii – the British Isles’ Bluefire jellyfish, to be specific). While orcas, elasmobranchs, and cephalopods are totally epic, I’d want to experience more effortless adaptations. The coolest thing about some jellyfishes, aside from their exquisite grace and utilization of energy, is that they can revert to their younger selves when food is scarce or their environment poses survival challenges. Who doesn’t want that superpower…they’re practically immortal!</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to someone starting out on this career path?</h2>
                <p>Don’t forsake your education! It’s crucial. In this current age, we are so privileged to have the opportunity to seek higher education - use it to your advantage…and use it while you’re young. Give yourself time to enjoy the beauty of knowledge, the confidence and power it gives you to make positive impacts for other species on this planet. Any chance you have to share that knowledge with someone else, do it. And never, never stop learning!</p>
              </div>
              {/* INTERVIEW MARISA*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Marisa</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your job title? Do you feel like that fully encapsulates all that you do?</h2>
                <p>The job title for my current assignment is Station Officer for the South Pole Atmospheric Research Observatory. This site is one of a few different observatories around the world that continuously collects climate data in order to monitor long term and global scale trends of compounds in the atmosphere (in other words, we track climate change), and my main role is to ensure that the station and all instruments continue to run smoothly throughout the year.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your education, training and/or experience that prepared you for this career.</h2>
                <p>During my years as an undergrad I studied physical oceanography (think tides and waves) and worked as a student assistant in an underwater robotics lab. I knew I was interested in a career working for NOAA, but at the time I was not sure how best to achieve that, or what direction I wanted to take. I actually discovered NOAA Corps entirely on accident, and although I was initially hesitant about the officer and uniform side of the job, I quickly realized that it was a perfect opportunity to travel the world while supporting environmental research.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is the biggest risk that you’ve taken along your career path?</h2>
                <p>The biggest risk for my career so far has actually been requesting to be assigned to the South Pole for one year. Working in such a remote location with very limited resources doesn't allow for many training opportunities or immediate job advancement. But, as I am now 10 months into my 13 month stay, I can say that it has definitely been worth it.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What was your first “win” that made you confident that you were doing the right thing?</h2>
                <p>My very first project at sea was working with a group of NOAA scientists to recover some moored ocean buoys that are used for monitoring and predicting El Nino and La Nina impacts. The buoys were due for regular maintenance, and for the first one I was responsible for maneuvering the ship to conduct the recovery. It's a stressful event, with the risk of injuring the crew or damaging the vessel and the instruments, but my training paid off and the buoy plus its few thousand meters of anchor chain were brought on board without an issue. I have since done that task more times than I can remember, but it was that first one that helped me realize just how much effort goes into continuously collecting data from the ocean and just how necessary my new role was in that process.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Why should youth want to pursue this marine science career, why is it important right now?</h2>
                <p>NOAA Corps provides an opportunity for a career that combines science, adventure, and leadership. I also feel like it has provided me with some skills to help bridge communications between scientific communities and the rest of the public. While being involved in or supporting environmental policy is never an easy task, it is valuable for helping society understand and adequately respond to the world around us.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Marine Geology and Ocean Mapping?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about BethElLee's responses? <a href="https://drive.google.com/file/d/175i5drIi501G3N5TCJ31pcH9pKVxfsa6/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Marisa's responses? <a href="https://drive.google.com/file/d/1_-xXrj8G1MDgHk95Q3uKAdplSovjoLT_/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://interactiveoceans.washington.edu/gallery/" target="_blank" rel="noopener noreferrer">Explore past and current oceanographic projects through the ocean observatories initiative.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.whoi.edu/what-we-do/explore/instruments/" target="_blank" rel="noopener noreferrer">Learn about oceanography instruments and equipment.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://tos.org/opportunities" target="_blank" rel="noopener noreferrer">Find out general job and career resources for oceanography.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.nationalgeographic.org/encyclopedia/oceanography/" target="_blank" rel="noopener noreferrer">Explore the different subsets of oceanography in this web portal.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.ocean.washington.edu/" target="_blank" rel="noopener noreferrer">University of Washington School of Oceanography</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="http://faculty.washington.edu/jkeister/" target="_blank" rel="noopener noreferrer">Explore Dr. Julie Keister's Biological Oceanography Research Projects.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://green2.kingcounty.gov/marine/Monitoring/Zooplankton" target="_blank" rel="noopener noreferrer">Check out this Zooplankton Monitoring Program.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.psp.wa.gov/PSmarinewatersoverview.php" target="_blank" rel="noopener noreferrer">PSEMP Marine Waters Report</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.mdpi.com/1424-2818/12/2/53" target="_blank" rel="noopener noreferrer">BethElLee's research article on Species Composition and Distribution of Jellyfish in a Seasonally Hypoxic Estuary.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.mdpi.com/1424-2818/12/1/21" target="_blank" rel="noopener noreferrer">BethElLee's research article on Zooplankton Community Response to Seasonal Hypoxia.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}