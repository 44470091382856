import React, { Component } from 'react';
import NewEquityIntro from './newEquity-Intro';
import NewEquityNews from './newEquity-News';
// import NewEquityPriorities from './newEquity-Priorities';
import NewEquityConsiderations from './newEquity-Considerations';
import NewEquityAccess from './newEquity-equityAccess';
// import NewEquityStatements from './newEquity-Statements';
import NewFooter from '../../pages-other/NewFooter';
import './newEquity-Content.css';

export default class NewEquityContent extends Component {
  render() {
    return(
      <div id="newEquity-content-container">
        <NewEquityIntro />
        <NewEquityNews />
        {/* <NewEquityPriorities /> */}
        <NewEquityConsiderations />
        <NewEquityAccess />
        {/* <NewEquityStatements /> */}
        <NewFooter />
      </div>
    );
  }
}