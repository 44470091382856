import React, { Component } from 'react';
import './annualReport2021Sharing-Blog.css';

export default class AnnualReportSharingBlog extends Component {
  render() {
    return(
      <div id="annualReport2021sharing-blog-container">
        <div className="annualReport2021sharing-blog-content-container">
                <div className="annualReport2021sharing-blog-banner-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/blog+bar-8.png" alt="blog highlights" />
                    <h2>Blog Highlights</h2>
                </div>
            <div className="annualReport2021sharing-blog-highlight">
                <h3>
                Equitable Family Engagement During the Pandemic 
                </h3>
                <a href="https://medium.com/the-foundry10-voice/educational-justice-starts-with-equitable-family-engagement-ce24e702fb95" target="_blank" rel="noopener noreferrer">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/equitable+family-100.jpg"  alt="educational justice starts with equitable family engagement"/>
                </a>
                <h4>
                We partnered with the Southeast Seattle Education Coalition and UW researchers Jondou Chen and Ann Ishimaru to understand how to more equitably engage families during COVID-19.  The results of this work are in this blog post.
                </h4>
            </div>
            <div className="annualReport2021sharing-blog-highlight">
                <h3>
                Gap Year Intern Stories
                </h3>
                <a href="https://medium.com/the-foundry10-voice/all-a-board-game-for-marine-science-learning-bbcd3b5c20e" target="_blank" rel="noopener noreferrer">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/gap+stories-100.jpg"  alt="all a-board game for marine science learning"/>
                </a>
                <h4>
                To support those who decided to take a gap year due to the pandemic, we offered internships to youth as a way to enhance their opportunities during this time. One of our interns applied her writing talents to foundry10 games work and her experience <a href="https://medium.com/the-foundry10-voice/the-worlds-most-chaotic-tv-show-adhd-in-2020-152399996df" target="_blank" rel="noopener noreferrer">living with ADHD in the pandemic</a>!
                </h4>
            </div>
        </div>
      </div>
    );
  }
}

