import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import ResourceHeader from '../components/resource-header.js';
import Breadcrumbs from '../components/breadcrumbs.js';
import ContentBlocks from '../components/ContentBlocks';
import ResearchTeam from '../components/researchTeam';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';
import Grid from '@material-ui/core/Grid';
import color from '@material-ui/core/colors/amber.js';
import Seomatic from '../components/base/seomatic.js';

const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const segment_array = segment_str.split( '/' );
const last_segment = segment_array.pop();

const QUERY = gql`
    query GetResource($slug: [String]) {
    entry(slug: $slug) {
      sectionHandle
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "Y-m-d")
      url
      ... on resources_default_Entry {
        customTitle
        blurb
        featuredImage {
          title
          url
        }
        thumbnailImage {
          title
          url
        }
        researchAsset {
          url
          customText
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              url
            }
          }
        }
        seomatic {
          metaLinkContainer
          metaTagContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
                emphasizedTitle
                emphasizedSubheadline
                emphasizedOverline
                emphasizedImage {
                    url
                }
                emphasizedImageCaption
                emphasizedContent
                emphasizedLink
                emphasizedBackgroundColor
            }
            ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
            }
            ... on contentOptions_quoteSlideshow_BlockType {
              quoteSliderTitle
              quotes {
                __typename  
                ... on quotes_BlockType {
                  quotesQuote
                  quotesQuoteImage {
                    url
                  }
                  quotesQuoteAttribution
                }
              }
            }
            ... on contentOptions_linkList_BlockType {
              linkListTitle
              linkListSize
              linkListList {
                __typename  
                ... on linkListList_BlockType {
                  linkListTitle
                  linkUrl
                  linkListBg {
                    url
                  }
                  linkListHoverTxt
                  linkListTitle
                }
              }
              linkListDisplay
              columnCount
            }
            ... on contentOptions_accordion_BlockType {
              accordionTitle
              accordion {
                __typename  
                ... on accordion_BlockType {
                  accordionItemTitle
                  accordionItemBlurb
                  accordionItemImage {
                    url
                  }
                  accordionItemIcon {
                    url
                  }
                  accordionItemContent
                }
              }
            }
            ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
            ... on contentOptions_list_BlockType {
              list {
                ... on list_BlockType {
                  id
                  listItemImage {
                    url
                  }
                  listItemHeadline
                  listItemRichContent
                  listItemLink {
                    url
                    customText
                    target
                  }
                }
              }
              listTitle
            }
            ... on contentOptions_slider_BlockType {
              id
              sliderTitle
              slides {
                ... on slides_BlockType {
                  id
                  slideImage {
                    url
                  }
                  slideHeadline
                  slideContent
                  slideLink {
                    customText
                    url
                  }
                }
              }
            }
            ... on contentOptions_thirdPartyCode_BlockType {
              id
              thirdPartyCodeTitle
              thirdPartyCodeCode
            }
            ... on contentOptions_featuredResource_BlockType {
              id
              featuredResource {
                title
                slug
                ... on resources_default_Entry {
                  id
                  blurb
                  featuredImageCaption
                  researchAsset {
                    url
                  }
                  thumbnailImage {
                    url
                  }
                }
              }
            }
            ... on contentOptions_featuredPerson_BlockType {
              id
              featuredPerson {
                ... on teamMembers_default_Entry {
                  id
                  teamMemberImage {
                    url
                  }
                  teamMembersPronouns
                  teamMemberPosition
                  teamMemberBio
                }
                title
                url
              }
            }
            ... on contentOptions_featuredStory_BlockType {
              id
              featuredstory {
                ... on stories_default_Entry {
                  id
                  slug
                  categories {
                    title
                  }
                  author {
                    fullName
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  researchExternalAuthors {
                    ... on researchExternalAuthors_BlockType {
                      id
                      researchExternalAuthorsAuthor
                    }
                  }
                  interestAreaTeam {
                    ... on interestAreaTeam_BlockType {
                      id
                      interestAreaTeamMember {
                        title
                      }
                    }
                  }
                  featuredImage {
                    url
                  }
                  blurb
                  title
                }
                dateCreated @formatDateTime(format: "F j, Y")
              }
            }
            ... on contentOptions_table_BlockType {
              id
              table {
                col1
                col2
                col3
              }
            }
            ... on contentOptions_insightsAndUpdates_BlockType {
              insightsAndUpdates {
                title
                slug
                ... on resources_default_Entry {
                  featuredImage {
                    url
                  }
                  title
                  resourceType {
                    title
                  }
                  slug
                  postDate @formatDateTime(format: "F j, Y")
                }
                ... on stories_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  categories {
                    title
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on press_default_Entry {
                  title
                  pressType {
                    title
                  }
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  url
                }
                ... on programs_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on projects_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                  projectType {
                    title
                  }
                }
              }
            }
        }
      }
    }
  }
`

const ResourceContent = (data) => {
  

  return (
    <section className='resourceContent'>
      <div className='container'>
        <Grid container spacing={40}>
          <Grid item md={8}>
            {data.data.featuredImageCaption != null ? <div className='resourceCitation'>
              <h4 className='text-small_bold'>Citation:</h4>
              <i onClick={() =>  navigator.clipboard.writeText(data.data.featuredImageCaption)} className="fa-solid fa-copy copyBtn"></i>
              <p className='text-small_bold'>{data.data.featuredImageCaption}</p>
            </div>:<div></div>}
            
            <div className="resourceBlurb">
            <div
              dangerouslySetInnerHTML={{
              __html: data.data.blurb,
              }}
            ></div>
            </div>
          </Grid>
          {data.data.thumbnailImage.length!==0 && data.data.thumbnailImage!==null  ? <Grid item md={4}>
            <div className='resourceDownload'>
            
            { <img src={data.data.thumbnailImage[0].url} alt="Resource Download" /> }
              
              {data.data.researchAsset && (
                <a className="text-half-step_bold" id="resourceDownloadClickId" href={data.data.researchAsset.url}>{data.data.researchAsset.customText}</a>
              )}
            </div>
          </Grid> : "" }
         

        </Grid>
      </div>
      {/* <div className='hotjarResourceFeedbackWidget' style={{ backgroundColor: '#F6F6F6', padding: '30px' }}></div> */}
    </section>
  );
}

const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  if (data?.entry) {
    return (
      <div>
        {data.entry.seomatic && (
          <Seomatic data={data.entry.seomatic} />
        )}
        <ResourceContent data={data.entry} />
        <ContentBlocks data={data.entry} />
        <div className='hotjarResourceFeedbackWidget' style={{ backgroundColor: '#FFFFFF', padding: '30px' }}></div>
      </div>
    );
  }

  return <div></div>;
}

export default class ResourceEntry extends Component {
  render() {
    return(
      <div className="resourceContainer">
        <Breadcrumbs variant='dark' slug={last_segment} />
        <ResourceHeader slug={last_segment} />
        <InlineContent slug={last_segment}/>
        <ResearchTeam slug={last_segment}/>
        {/* <GlobalFooter /> */}
        <NewFooter />
      </div>
    );
  }
}