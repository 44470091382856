import React, { Component } from 'react';
import { gql, useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import StoryCard from '../../craft-graphql/components/storyCard';
import './newEquity-News.css';

const equityStoriesQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 3, relatedToCategories: [{ id: 3069 }]) {
        title
        slug
        url
        author {
          fullName
        }
        postDate @formatDateTime (format: "M j, Y")
        ... on stories_default_Entry {
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          blurb
          featuredImage {
            url
          }  
          categories {
            title
          }
        }
      }
    }
`

const RecentStoriesForEquityCards = () => {
  const { data, loading, error } = useQuery(equityStoriesQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block) => {
        
        let props = {
          block: {block}
        }
        return (
          <Grid item xs={12} sm={4}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
  }  

  const RecentStoriesForEquityBlock = () => {

    return (
      <section className='equityCraftStories'>
          <div id="equity-craftStories-container">
          <div className="equity-craftStories-content-container">
            <h2 className="equity-craftStories-title"><span>Latest Stories Related to Equity and Access</span></h2>
          <section className='equityCraftHighlightCards'>
              <section className='equityCraftStories'>
                <div className="container">
                  <Grid container spacing={4}>
                    <RecentStoriesForEquityCards />
                  </Grid>
                </div>
             </section>
          </section>   
          <div className="equity-craftStories-bottomLink">
                <a href='/stories?cat=3069' className="equity-craftStories-content-bottomLink">Explore more stories<i className="fa-solid fa-arrow-right-long"></i></a>
              </div>
          </div>
          </div>
      </section>
    )
  }


export default class NewEquityNews extends Component {
  render() {
    return (
      // <div className="newEquityNews-container">
      //   <div className="newEquityNews-content-container">
      //     <h2>Latest News</h2>
      //     <ul className="newEquityNews-cardsList">
      //       <a href="https://medium.com/the-foundry10-voice/hilltop-school-supporting-pregnant-and-parenting-students-4bce2825f197" target="_blank" rel="noopener noreferrer">
      //         <li>
      //           <div className="newEquityNews-card">
      //             <div className="newEquityNews-card-image">
      //               <img src='https://f10-equity.s3.us-west-2.amazonaws.com/Equity+Page+Images/Equity-page-thumbnail_Hilltop.jpg' width="100%" alt="Illustration of two young pregnant people interacting."/>
      //             </div>
      //             <div className="newEquityNews-card-content">   
      //               <div className="newEquityNews-card-content-container">
      //                 <div className="newEquityNews-text">
      //                   <div className="newEquityNews-textOne">
      //                     <h3>Supporting Pregnant and Parenting Students</h3>
      //                   </div>
      //                   <div className="newEquityNews-textTwo">
      //                     <span>October 23, 2023</span>
      //                   </div>
      //                 </div>
      //               </div>                 
      //             </div>
      //           </div>
      //         </li>
      //       </a>
      //       <a href="https://medium.com/p/d2ea54a06d76" target="_blank" rel="noopener noreferrer">
      //         <li>
      //           <div className="newEquityNews-card">
      //             <div className="newEquityNews-card-image">
      //               <img src='https://f10-equity.s3.us-west-2.amazonaws.com/Equity+Page+Images/Sawhorse_Equity-Page_Thumbnail.jpg' width="100%" alt="Student using a buzz saw to cut materials."/>
      //             </div>
      //             <div className="newEquityNews-card-content">   
      //               <div className="newEquityNews-card-content-container">
      //                 <div className="newEquityNews-text">
      //                   <div className="newEquityNews-textOne">
      //                     <h3>Youth at Sawhorse Revolution Build 3 New Community Projects</h3>
      //                   </div>
      //                   <div className="newEquityNews-textTwo">
      //                     <span>September 5, 2023</span>
      //                   </div>
      //                 </div>
      //               </div>                 
      //             </div>
      //           </div>
      //         </li>
      //       </a>
      //       <a href="https://medium.com/the-foundry10-voice/how-to-make-your-own-kind-of-music-in-library-makerspaces-451a095aada6" target="_blank" rel="noopener noreferrer">
      //         <li>
      //           <div className="newEquityNews-card">
      //             <div className="newEquityNews-card-image">
      //               <img src='https://f10-equity.s3.us-west-2.amazonaws.com/Equity+Page+Images/Equity-Page_Makerspace_Thumbnail.jpg' width="100%" alt="Jessica Hughes and Kate Falconer"/>
      //             </div>
      //             <div className="newEquityNews-card-content">   
      //               <div className="newEquityNews-card-content-container">
      //                 <div className="newEquityNews-text">
      //                   <div className="newEquityNews-textOne">
      //                     <h3>How to Make Your Own Kind of Music in Library Makerspaces</h3>
      //                   </div>
      //                   <div className="newEquityNews-textTwo">
      //                     <span>July 19, 2023</span>
      //                   </div>
      //                 </div>
      //               </div>                 
      //             </div>
      //           </div>
      //         </li>
      //       </a>
      //     </ul>
      //   </div>
      // </div>
      <RecentStoriesForEquityBlock />
    );
  }
}