import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class MikaylaMarc extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Submersible Operations</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Submersible Operations"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in submersible operations. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Submersible Operations" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in submersible operations. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/submersible-operations" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/submersible-operations" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Submersible Operations",
                "description": "Explore an exciting marine science career in submersible operations. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/submersible-operations"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            {/* <div class="marineCareers-header-title-container">
              <h1>Submersible Operations</h1>
              <h2>Meet Mikayla</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Mikayla+Monroe.JPG" alt="mikayla monroe"/>
                </div>
              </div>
              <p>Mikayla Monroe is a Data Analyst and Submersible Pilot at OceanGate, Inc., provider of manned submersibles headquartered at the Port of Everett. Inspired by marine biologists on Shark Week when she was in 5th grade, Mikayla got involved in hands-on activities through beach cleanups, habitat restorations, and volunteering at the Seattle Aquarium. Following those childhood ambitions has led her to log 16 submersible dives and counting.</p>
              <p>Mikayla is also an alum of the Ocean Research College Academy (ORCA), where she presented research on heavy metal concentrations in human hair and river otter fur at 8 national and international conferences. Mikayla graduated a year early from college with the 2018-2019 Outstanding Graduating Student in Management Information Systems (MIS) Award at Western Washington University. She continues to be involved in outreach for young women in STEM and in her spare time enjoys running, kickboxing at 30 Minute HIT, and competing in relay races like Ski-to-Sea and Bellingham Bay Marathon Relay.</p>
            </div> */}
            <div class="marineCareers-header-title-container">
              <h1>Submersible Operations</h1>
              <h2>Meet Marc</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Marc+Taylor.PNG" alt="marc taylor"/>
                </div>
              </div>
              <p>Marc Taylor is an individual with a diverse background and an extensive skill set, which can be utilised in many different environments and situations within the yachting industry. An effective positive member of any team with leadership experience gained with the Royal Marines and in the Private security industry. Always looking to improve and become an effective asset.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW WITH MIKAYLA*/}
              {/* <div class="marineCareers-header-title-container">
                <h2>Interview with Mikayla</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your job title? Do you feel like that fully encapsulates all that you do?</h2>
                <p>My job title is “Data Analyst and Submersible Pilot.” Data Analyst means I get to help multiple teams, like sales and marketing, operations, and engineering, visualize their data and answer questions. And as a Submersible Pilot I am one of the people who pilots and co-pilots the sub. So yes, I would say that my title encapsulates most of my job.</p>
                <p>However, I think that with most jobs in the world there are going to be tasks that fall outside of your role, and that’s especially true in a small company. For instance, my co-workers and I take out the trash and recycle, but that doesn’t mean we call ourselves the janitor.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a submersible pilot.</h2>
                <p>It depends on the day. If you’re on expedition or doing dive training, you’ll be piloting and exploring underwater often. You’ll be meeting and communicating with researchers or the sub crew to understand and agree on the dive objectives, the location, what you want to observe or record, and how you want to approach the dive site.If you’re in-between expeditions, you’re taking time for maintenance and improvements to the sub itself.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your education, training and/or experience that prepared you for this career.</h2>
                <p>Growing up, my sister and I worked with my mom in broadcast journalism and worked the booth at outreach events. As I grew up (and after watching Shark Week), I became involved in volunteering at habitat restorations, beach cleanups, and as a High School Volunteer at the Seattle Aquarium. Then, during my junior and senior year of high school, I studied marine science through the Ocean Research College Academy (ORCA) here at the Port of Everett. It’s a hands-on Running Start program that has its own research vessel! You finish your last two years of high school while completing your first two years of college. When you graduate from high school, you also graduate with an Associate’s degree. ORCA and its professors establish a really solid foundation from which to grow, wherever you go next in life.</p>
                <p>In college at Western Washington University I majored in Management Information Systems (MIS) and minored in Business Analytics and took classes in computer science and Arabic. Traditionally, Submersible Pilots have a background in engineering or serve in the Navy. At OceanGate, each pilot has their own unique background. This helps us approach problems from different perspectives and provide a fresh look to traditions. Lots of Submersible Piloting skills are on-the-job training, and there’s always something new to learn.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What was your first “win” that made you confident that you were doing the right thing?</h2>
                <p>I think my first “win” in my Submersible Piloting skills was my first attempt at landing Cyclops 1 on the Platform. By “Platform,” I mean our launch and retrieval system, which allows us to tow to the dive site faster since the sub isn’t in the water, dragging and potentially pulling out cables or getting twigs caught in the systems. When we get out to the dive site, the platform sinks below the surface, then the sub detaches and goes to dive. When the dive is complete, the sub lands on the platform while it is underwater, then they are both brought back up to the surface.</p>
                <p>Throughout the dive, our crew had amazing encounters with dogfish sharks and Red Stripe Rockfish and I wanted it to end well. Fortunately, we had great weather and very little current or wind. As I had hoped, the landing process went smoothly and I was successful on my very first attempt. It certainly wouldn’t have gone as well without Chief Sub Pilot Kenneth coaching me and helping control buoyancy, which I really appreciate. But after that event, I went home that day and thought to myself, “Huh, I just landed a sub on an underwater platform on my very first try, ever.” Of course, I didn’t get arrogant—the water was calm and Kenny was helping control the buoyancy, but I certainly viewed it as a good sign/”win”.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What do you want young people starting out in their career to know?</h2>
                <p>I want everyone to know that it is okay to change your mind. It’s okay to change your major, it’s okay to change your career path if you find that it’s no longer your passion. Do not feel pressure to stay with something simply because you feel you owe it to your past self. Your skills will be transferable in other roles and many places allow time for on-the-job training. Don’t feel trapped—there are always other options.</p>
              </div> */}
              {/* INTERVIEW WITH MARC*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Marc</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your job title? Do you feel like that fully describes all that you do?</h2>
                <p>My current job title is dive manager. I look after all the dive operations aboard my vessel, which includes all the dive center, the dive equipment, dive planning, and the submersible side of the dive department. The dive manager title sort of describes my job. I've got a couple of guys and girls that are underneath me so I work with them. Yeah, I manage that whole department, so I would say pretty much describes it quite well.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your education, training or experience that prepared you for this career.</h2>
                <p>I've got a military background which sort of trained me well for dealing with stressful situations. Education wise, it's very much on-the-job education.I haven't got any university sort of training or college development or anything like that within the specific areas of the job. I've obviously got lots of diving training, but specific technical sides of the job - that's where the additional training, and the additional reading up, and making sure you are up to speed on certain aspects of the job is. You go through a training process there to be able to operate the equipment and be able to service and maintain it.</p>
                <p>The life skills and the life experiences I've had have very much given me that base platform, and then the bolt-on courses and the education modules that I have taken have given me that basis that I can conduct my job in a correct manner.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>How has the field of diving and submersible operations changed in the past five years? What do you predict might happen in the next five to ten years?</h2>
                <p>A lot of people are getting more excited about the whole aspect of being pioneers in their own right.  Those who have the income to be able to purchase one of these units has very much hit the market now. People are taking notice and understanding that it is a great opportunity. So that side of the industry is growing quite well, which in turn has led to different boats, expedition boats being built, which then generates more within the industry.</p>
                <p>The diving industry has always maintained a nice steady rate. Everybody still likes to go diving so that will always maintain. The whole yachting industry and the whole expedition side, what we're involved in has definitely taken a turn for the better. A lot more expedition boats are getting built. So yeah, a steady rise within the industry and changing with how they've got to deal with their leisure time and explore the underwater world.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to somebody starting out on this career path?</h2>
                <p>If it was easy, everybody would do it. And that's what makes the industry worth it. I would say that though times do get hard, they're not always going to be hard. The 95% of the time when you think the job is very difficult and terrible and arduous and tiring and relentless, the 5% that you get back from it is worth every bit. With every great prize is always a long arduous journey to get there.</p>
                <p>And also, you are going to meet odd characters that are hilarious in their own particular way. And that's where tolerance comes in. If you have an open mind, you will find strengths in many ways with any characters. Yeah, my advice would be to have determination and open-mindedness because if you do stick with it, eventually you're going to maybe reach that dream or even surpass it with something that you never thought possible.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Submersible Operations?</h2>
                  <ul class="marineCareersLearning-list">
                    {/* <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Mikayla's responses? <a href="https://drive.google.com/file/d/1aW5ECQCVYcPH0udBN34YK5ux_J53S4Sn/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li> */}
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Marc's responses? <a href="https://drive.google.com/file/d/1nJFXC1Dp0Ai4svbyNUZUjc9DDCtl1jsX/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.communitysubmersiblesproject.com/" target="_blank" rel="noopener noreferrer">Learn what it takes to dive in a submersible.</a></p>
                        </div>
                      </div>
                    </li>
                    {/* <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.youtube.com/watch?v=zF1K5E8QGRg&feature=emb_title" target="_blank" rel="noopener noreferrer">Watch Macklemore ride in a submersible in search of sixgill sharks.</a></p>
                        </div>
                      </div>
                    </li> */}
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.whoi.edu/what-we-do/explore/underwater-vehicles/hov-alvin/" target="_blank" rel="noopener noreferrer">Learn about Alvin, one of the world's first deep-ocean deep submersibles.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://oceangate.com/gallery.html" target="_blank" rel="noopener noreferrer">Explore past submersible operations in this photo and video gallery.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}