import React, { Component } from 'react';
import './annualReport2021Covid-Needs.css';

export default class AnnualReportCovidNeeds extends Component {
  render() {
    return(
      <div id="annualReport2021covid-needs-container">
        <div className="annualReport2021covid-needs-content-container">
                <div className="annualReport2021covid-needs-banner-container" id="annualReport2021covid-needs-banner-presenting">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Covid+Page/needs.png" alt="meeting basic needs"/>
                  <h2>Meeting Basic Needs</h2>
                </div>
            <div className="annualReport2021covid-needs-highlight">
                <br />
                <br />
                <h3>
                All children need and deserve secure housing, nutritious food, clean clothes, and a sense of safety before they can really focus on learning. The need for distance learning support was great, but the urgency of wraparound support was greater. Parents lost jobs. Relatives caught the virus. Families who were struggling against the current of systemic racism and income inequality long before the pandemic, now found themselves experiencing even greater hardship. To best support our partners in meeting the basic needs of youth and families in their communities, we listened, learned, and acted fast. Here are a few ways we pivoted and met basic needs. 
                </h3>
                <h2>
                Gift Cards
                </h2>
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Covid+Page/gift+cards.jpg" alt="gift cards"/>
                <h3>
                The school family liaisons in our network advised us that gift cards were a great alternative to donating food or writing a check. Gift cards are easier for schools and organizations with limited storage space to collect and distribute, and they offer families the agency to decide what they need. Our community partners offered important insight into which cards would work best for the families they serve. Many requested gift cards from grocery stores like Safeway because they are ubiquitous in the Seattle area; others said that gift cards from super stores like WalMart were preferable because they had lower prices and offered more non-food items like cleaning products, clothing, and toiletries. Visa gift cards were incredibly useful to several organizations, because they allow families to meet other essential needs like transportation, prescription eyeglasses or to pay a fee for a driver’s test.
                </h3>
            </div>
            <div className="annualReport2021covid-needs-highlight">
                <h2>
                Outreach
                </h2>
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Covid+Page/bipoc.jpg"  alt="outreach"/>
                <h3>
                As we pivoted from our typical philanthropic operations through educational pathways, we assembled a small team to lead outreach and facilitate logistics for direct giving. The outreach team contacted school-based family advocates, social service agencies, medical facilities, BIPOC-led and serving nonprofits, and mutual aid networks. By keeping barriers to aid low, trusting our team, and making new community connections, we were able to rapidly spread our resources widely. Organizational leaders and school liaisons request support for things like paying off utility bill balances, funding rental assistance, supporting the Harborview COVID-19 Vaccination Program for Vulnerable Populations, donating to pop-up food pantries, contributing to flex funds for community organizations that work directly with families, and more. By joining the conversation with a spirit of openness, we were able to quickly make an impact for youth and families.
                </h3>
            </div>
        </div>
      </div>
    );
  }
}
