import React, { useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";

const Slide = ({ data }) => {
  const imageUrl = useMemo(() => {
    if (data?.slideImage?.length) {
      return data.slideImage[0].url;
    }
    return null;
  }, [data]);

  return (
    <div className="slide" key="Test">
      <Grid container>
        <Grid item md={6} className="slideImage">
          {imageUrl && <img alt="" src={imageUrl} />}
        </Grid>
        <Grid item md={6} className="slideContent">
          <h3>{data.slideHeadline}</h3>
          {data.slideHeadline && (
            <div
              dangerouslySetInnerHTML={{
                __html: data.slideContent,
              }}
            />
          )}
          {data.slideLink.customText ? (
            <a className="button" href={data.slideLink.url}>
              {data.slideLink.customText}
            </a>
          ) : (
            data.slideLink.url && (
              <a className="button" href={data.slideLink.url}>
                {data.slideLink.url}
              </a>
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const StandardSlideshow = ({ block }) => {
  const settings = {
    dots: true,
    centerPadding: "60px",
    swipeToSlide: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          centerPadding: "350px",
        },
      },
      {
        breakpoint: 1615,
        settings: {
          centerPadding: "250px",
        },
      },
      {
        breakpoint: 1370,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1250,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1150,
        settings: {
          arrows: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 675,
        settings: {
          centerMode: false,
          arrows: true,
        },
      },
      {
        breakpoint: 450,
        settings: {
          centerMode: true,
          centerPadding: "0px",
          arrows: false,
        },
      },
    ],
  };
  return (
    <section className="standardSlideshow">
      <div className="container">
        {block.sliderTitle && (
          <h3 className="text-xxl_bold text-left emphHeader">
            <span>{block.sliderTitle}</span>
          </h3>
        )}

        <div className="slideShowContainer">
          <Slider {...settings}>
            {block.slides.map((slide, index) => (
              <Slide key={index} data={slide} />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default StandardSlideshow;
