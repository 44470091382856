import React, { Component } from 'react';
import './annualReport2021Sharing-Graphics.css';

export default class AnnualReportSharingGraphics extends Component {
  render() {
    return(
      <div id="annualReport2021sharing-graphics-container">
        <div className="annualReport2021sharing-graphics-content-container">
                <div className="annualReport2021sharing-graphics-banner-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/graphic+highlights+bar-8.png" alt="graphic highlights"/>
                    <h2>Graphic Highlights</h2>
                </div>
            <div className="annualReport2021sharing-graphics-highlight">
                <h3>
                Gap Year White Paper 
                </h3>
                <a href="https://drive.google.com/file/d/1F1pc3Oy-5-lRGhWx6k02rUMwd6y6n5iu/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/gapyear.jpg" alt="gap year"/>
                </a>
                <h4>
                The COVID-19 pandemic changed how many youth thought about their college plans in crucial ways.  We surveyed over 300 youth about their plans and how they were impacted by the pandemic. This whitepaper shows the findings from this timely work.
                </h4>
            </div>
            <div className="annualReport2021sharing-graphics-highlight">
                <h3>
                Careers in Audio Poster
                </h3>
                <a href="https://drive.google.com/file/d/1HFf-9Mm1cQfU-eX2Fk-PMjYWJf56xghg/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/careers-100.jpg"  alt="careers in audio poster"/>
                </a>
                <h4>
                Expanding how youth consider career pathways is an important element of our work.  This poster is the second in a series showing the immense number of careers in audio production so that youth can better grasp the wide array of opportunities within the field.
                </h4>
            </div>
        </div>
      </div>
    );
  }
}

