import React, { Component } from 'react';
import './annualReport2021Covid-Equity.css';

export default class AnnualReportCovidEquity extends Component {
  render() {
    return(
      <div id="annualReport2021covid-equity-container">
        <div className="annualReport2021covid-equity-content-container">
                <div className="annualReport2021covid-equity-banner-container" id="annualReport2021covid-equity-banner-presenting">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Covid+Page/racial+equity_1-8.png" alt="covid supporting racial equity"/>
                  <h2>Supporting Racial Equity</h2>
                </div>
            <div className="annualReport2021covid-equity-highlight">
                <br />
                <br />
                <h3>
                Amidst the pandemic, and a fractured political landscape, the social movements of the past year amplified the voices of the underrepresented. With this came a responsibility to act differently going forward. We pivoted in our thinking and, in turn, made necessary changes to move forward in more positive, equitable, and impactful directions. Still, we understand that we are not perfect, and are working to celebrate each opportunity to learn from our mistakes while striving to be better across our work. 
                </h3>
                <h2>
                Focused Support for BIPOC-Serving and Led Orgs
                </h2>
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Covid+Page/bipoc.jpg" alt="focused support for bipoc serving and led orgs"/>
                <h3>
                Tragic acts of racial violence and injustice across the country amplified underrepresented voices that continued the call for a more just society. This call asked us to pause and reflect on how we might better meet the needs of Black, Indigeneous, and people of color youth and communities. We recognized that it was important to more intentionally center equity across our work and also, crucially, to do so not only through statements but through actions that would back those up. 
                </h3>
                <h3>
                While we are continuing to push ourselves toward greater action, we took important steps in delivering on our commitment to BIPOC youth during the past year. As an organization, we recognized that we could make an impact by directing funding to partner organizations who work with underrepresented populations of youth. In summer 2020, we made donations and connected with several Black-led orgs, including Black Heritage Museum, East African Community Services, FEEST, iUrbanTeen, and Urban Metropolitan League of Seattle. We are beginning to grow longer-term relationships with a few of these organizations and look forward to continuing to grow and develop in this space.    
                </h3>
                <h3>
                Additionally, we committed to expand our own knowledge base by bringing in diversity, equity, and inclusion training sessions for the coming year. We want to provide safe, positive learning environments for the youth that we are privileged to work with. Providing training sessions to each employee will ensure that we are providing the best possible outcome for both the youth and the adults involved. We are committed to providing the support and educational resources to the foundry10 team as we continue to evolve and grow.     
                </h3>
            </div>
            <div className="annualReport2021covid-equity-highlight">
                <h2>
                Gap Year Whitepaper Finding on BIPOC Youth
                </h2>
                <a href="https://drive.google.com/file/d/1F1pc3Oy-5-lRGhWx6k02rUMwd6y6n5iu/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/gapyear.jpg" alt="gap year"/>
                </a>
                <h3>
                As U.S. universities transitioned to remote learning for the 2020-2021 academic year, many students adjusted their educational plans due to the coronavirus pandemic. Research found that roughly 1 in 6 high school seniors in the U.S. reported that they had intended to change their plans to attend college in the fall because of the pandemic (Art&Science Group, 2020), with graduates from lower-income backgrounds experiencing different barriers than their wealthier peers (Art&Science Group, 2020).
                </h3>
                <h3>
                With COVID-19 rapidly changing the educational landscape, we thought more information was needed regarding first-year student experiences with transitioning to (or delaying) college, as well as barriers in furthering their education. To explore this, we examined the impact of COVID-19 on Washington State high school graduates’ intentions to attend college in the 2020-2021 academic year. We assessed plans for college attendance, barriers to college attendance, and students’ sense of identity as they transition to young adulthood in the COVID-19 era. Additionally, we looked at potential equity differences in students’ educational plans (e.g., income, race/ethnicity, first generation student status).
                </h3>
                <h3>
                What we found is that low-income students and students of color may need additional support for future undergraduate enrollment. Roughly 2 in 5 participants changed their educational plans, with more students from lower income families altering their plans due to the COVID-19 pandemic. Additionally, more students who are from lower income families and/or are BIPOC reported delaying postsecondary enrollment to join the workforce compared to their white and wealthier peers. These findings suggest that students from lower socioeconomic backgrounds and students of color may be more likely to suffer hardships as a result of the coronavirus pandemic and are in need of assistance as they plan for future undergraduate enrollment.
                </h3>
                <h3>
                We also focused on providing some concrete ways that schools and other organizations can support low-income students and students of color, such as offering both informational and instrumental social support to encourage future enrollment. Providing this kind of support for student enrollment and success is important, because students from lower-income families and those who are BIPOC tend to have a more difficult time accessing online learning materials and having an ideal environment for remote learning.
                </h3>
            </div>
        </div>
      </div>
    );
  }
}
