import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class JoseGuzman extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Academia</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Academia"
          />
          <meta
            name="description"
            content="Explore an exciting academia career in the marine sciences. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Academia" />
          <meta
            property="og:description"
            content="Explore an exciting academia career in the marine sciences. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/academia" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/academia" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Academia",
                "description": "Explore an exciting academia career in the marine sciences. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/academia"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h1>Academia</h1>
              <h2>Meet José</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Jose+Guzman.jpg" alt="josé guzman"/>
                </div>
              </div>
              <p>Dr. José M. Guzmán is an Assistant Teaching Professor in the Marine Biology and the Aquatic and Fishery Sciences programs, at the University of Washington. He grew up in Córdoba (Spain) and received his Ph.D. in Marine Sciences from the University of Cádiz (Spain) in 2010. In his research, Jose studies the mechanisms that regulate the onset and progression of sexual development in fishes, and how this knowledge can be used by the fishing and aquaculture industries to produce sustainable seafood. Jose is passionate about effective teaching, and the science behind it. He develops specific pedagogical strategies to promote flexibility, accessibility, and higher order thinking in his courses. Jose is recipient of the UW Distinguished Teaching Award, and the College of the Environment Award for Outstanding Teaching Faculty. Photo credit goes to Dan Dinicola, communication specialist at the University of Washington.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW */}
              <div class="marineCareers-header-title-container">
                <h2>Interview with José</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a marine biology professor and researcher.</h2>
                <p>Interacting with students is the engine of my daily life at work! I love it! I spend most of my time trying to come up with challenges for my students, with opportunities and questions to make them think and make them apply whatever we are dealing with that day. I use “active learning” in my classroom, which is based on the idea that students learn better by doing: being engaged in conversations, talking to each other, explaining stuff to their peers – rather than sitting there just listening.</p>
                <p>Although most of my time is focused on teaching, I manage to keep research going on. I am interested in the mechanisms that regulate the onset of the sexual maturation in fishes, and how this knowledge can be used by the fishing and aquaculture industries to produce sustainable seafood.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your education, training and/or experience that prepared you for this career.</h2>
                <p>I completed an undergraduate and Master’s in Marine Sciences at the University of Cadiz, in the southwest of Spain, and lived in The Netherlands for a year where I worked at the University of Nijmegen. After that I moved back to Spain and completed a PhD in Marine Sciences at the National Research Council. During that time, I had the opportunity to work in labs in Bergen (Norway) and Baltimore (MD). After that, I moved to Seattle, where I was a postdoc researcher at the Northwest Fisheries Science Center (NOAA)…and from there to the University of Washington!</p>
              </div>
              <div class="marineCareers-interview">
                <h2>How has the field of marine biology changed in the last decade?</h2>
                <p>In the lab, the “omics” technologies (genomics, transcriptomics, proteomics, and metabolomics) have transformed the way we move forward in the field of animal physiology. These technologies allow you to investigate in great detail how the information at a cellular and subcellular level is processed, and they have become very affordable and accurate in the last few years – and they generate a lot of data! At this point I feel that the limiting factor is not how to obtain more data, but how to analyze it and make sense of it.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your favorite marine science fact and if you could be any marine animal, what would you be?</h2>
                <p>Freshwater fish do not drink water, and pee a lot, whereas marine fish drink a lot of water and barely pee – crazy eh? This is just a strategy to maintain an internal concentration of ions. My favorite marine animal is the bluefin tuna – did you know that bluefin tuna keep their heart pumping during temperature changes that would stop a human heart? Another fun fact.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Why should youth consider a career in Marine Biology?</h2>
                <p>As we all know, marine life is a critical reservoir of food, medicine, and raw material in addition to recreation and tourism. But also, at a very fundamental level, marine life determines the fate of our planet – and the lives of most of the living things on it. For example, up to 80% of the oxygen we breathe is produced in the ocean! We need informed citizens and scientists that use evidence-based approaches to take advantage of this delicate system without putting it at risk. The clock is ticking.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Marine Science Academia?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about José's responses? <a href="https://drive.google.com/file/d/1aVPg5hoPFf34FCFo6y3Tt6y6VzRpiHob/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.theacademicsociety.com/gradblog/when-to-start-applying-for-jobs-in-academia" target="_blank" rel="noopener noreferrer">Learn about tips & tricks on how to start applying for jobs in academia, and what they may be looking at this webpage.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://marinebio.org/careers/international-schools/" target="_blank" rel="noopener noreferrer">Check out lots of different places where you can get marine science degrees all over the world.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://oceanfirsteducation.blue/who-we-serve/teachers-and-educators" target="_blank" rel="noopener noreferrer">Find curriculum and different marine biology educational resources here on this website.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}