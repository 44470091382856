import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  useQuery,
  gql
} from "@apollo/client";
import Seo from '../components/base/seo.js';
import HomepageHeader from '../components/homepage-header.js';
import ContentBlocks from '../components/ContentBlocks';
import GlobalFooter from '../components/base/GlobalFooter';
import StoryCard from '../components/storyCard';

const QUERY = gql`
    query NewQuery($slug: [String]) {
    entry(slug: $slug) {
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "Y-m-d")
      url
      ... on homepage_homepage_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
              url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename
            }
          }
          ... on contentOptions_slider_BlockType {
            sliderTitle
            slides {
              __typename
              ... on slides_BlockType {
                slideImage {
                  url
                }
                slideHeadline
                slideContent
                slideLink {
                  customText
                  url
                  target
                }
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_list_BlockType {
            listTitle
            list {
              __typename  
              ... on list_BlockType {
                listItemHeadline
                listItemRichContent
                listItemImage {
                  url
                }
                listItemLink {
                  url
                  customText
                  target
                }
              }
            }
          }
          ... on contentOptions_linkList_BlockType {
            linkListTitle
            linkListList {
              __typename  
              ... on linkListList_BlockType {
                linkListTitle
                linkUrl
                linkListBg {
                  url
                }
                linkListHoverTxt
                linkListTitle
              }
            }
            linkListDisplay
            linkListSize
            columnCount
          }
          ... on contentOptions_video_BlockType {
            videoTitle
            videoLink
            videoCaption
            videoImage {
              url
            }
          }
          ... on contentOptions_featuredResource_BlockType {
            featuredResource {
              title 
              slug
              url
              ... on resources_default_Entry {
                blurb
                researchAsset {
                  url
                  title
                }
                  thumbnailImage{
                    url
                  }
                interestAreaTeam {
                  ... on interestAreaTeam_BlockType {
                    interestAreaTeamMember {
                      title
                      url
                    }
                  }
                }
                researchExternalAuthors {
                  ... on researchExternalAuthors_BlockType {
                    researchExternalAuthorsAuthor
                  }
                }
                featuredImageCaption
              }
            }
          }
          ... on contentOptions_featuredPerson_BlockType {
            featuredPerson {
              title 
              url
              ... on teamMembers_default_Entry {
                teamMembersPronouns
                teamMemberRoles {
                  title
                }
                teamMemberImage {
                  url
                }
                teamMemberPosition
                teamMemberBio
              }
            }
          }
          ... on contentOptions_featuredStory_BlockType {
            featuredstory {
              title 
              url
              slug
              author {
                fullName
              }
              postDate @formatDateTime(format: "F j, Y")
              dateCreated @formatDateTime (format: "M j, Y")
              ... on stories_default_Entry {
                blurb
                featuredImage {
                  title
                  url
                }
                categories {
                  title
                }
                themes {
                  title
                }
                interestAreas {
                  title
                }
                researchExternalAuthors {
                  ... on researchExternalAuthors_BlockType {
                    researchExternalAuthorsAuthor
                  }
                }
                interestAreaTeam {
                  ... on interestAreaTeam_BlockType {
                    interestAreaTeamMember {
                      title
                    }
                  }
                }
              }
            }
          }
          ... on contentOptions_thirdPartyCode_BlockType {
            thirdPartyCodeTitle
            thirdPartyCodeCode
          }
        }
      }
    }
  }
`

const RecentStories = () => {
  const query = gql`
    query NewQuery {
      entries(section: "stories", limit: 3, offset: 0) {
        postDate @formatDateTime(format: "F j, Y")
        ... on stories_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          categories {
            title
          }
          themes {
            title
          }
          interestAreas {
            title
          }
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
        }
      }
    }
  `;
  const { data, loading, error } = useQuery(query);

  if (loading) {
    return <div>loading</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='relatedPosts'>
      <div className='container'>
        <h2 className='text-large'>Recent Stories</h2>
        <Grid container spacing={24}>
          {data.entries.map(
            (block, index) => {
              return (
                 <Grid key={index} item sm={12} md={4}>
                  <StoryCard block={{ block }} />
                </Grid>
              )
            }
          )}
        </Grid>
      </div>
    </div>
  );
};

const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  return (
    <Fragment>
      {/* <Seo data={data.entry.seo} /> */}
      <RecentStories />
      <ContentBlocks data={data.entry} />
    </Fragment>
  );
}


const Homepage = () => {
  return(
    <section className='homepageContent'>
      <HomepageHeader slug='homepage' />
      <InlineContent slug='homepage'/>
      <GlobalFooter />
    </section>
    
  );
}

export default Homepage;
