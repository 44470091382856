import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class BrennaMaloney extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Science Communication</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Science Communication"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in communications. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Science Communication" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in communications. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/science-communication" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/science-communication" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Science Communication",
                "description": "Explore an exciting marine science career in communications. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/science-communication"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h1>Science Communication</h1>
              <h2>Meet Brenna</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Brenna+Maloney.JPG" alt="brenna maloney"/>
                </div>
              </div>
              <p>Brenna Maloney is an award-winning writer and editor with more than 20 years’ publishing experience. A long-time editor for The Washington Post and National Geographic, Brenna is currently the managing editor of National Geographic’s Explorer magazine. She continues to write articles and nonfiction book reviews for The Post. Brenna is also the author of ten books. She lives and works in Washington, D.C.</p>
            </div>
            <div class="marineCareers-header-title-container">
              <h2>Meet Bridget</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Bridget+Moran.jpg" alt="bridget moran"/>
                </div>
              </div>
              <p>Bridget Moran is a Conservation Associate in the Puget Sound and Columbia River Basin for American Rivers. She works to protect some of Washington’s finest rivers as Wild and Scenic. She also works on hydropower projects in Washington and Oregon. Bridget has extensive technical fisheries experience with the Washington Department of Fish and Wildlife, Whatcom Marine Resources Committee, Skagit River System Cooperative, and Trout Unlimited. Her grassroots advocacy experience stems from her work with North Sound Trout Unlimited, in which she serves as Chapter President. Bridget holds a Bachelor of Arts in Special Education from Western Washington University and an Associate of Applied Science in Fisheries and Aquatic Science from Bellingham Technical College. When not at work, she lets loose in the mountains and rivers with bikes, skis, and fishing rods.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW WITH BRENNA */}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Brenna</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a scientific writer. What do you look forward to most during your workday?</h2>
                <p>I enjoy most stages of the process, to be honest. When you first get an inkling for a story idea. When you do that early research and reporting on a topic—how many possibilities you can see. As you report the story, connecting with experts and with real people who are affected by your subject. I like the strategy and precision involved in creating the narrative arc of the story. I enjoy the writing, finding the exact words you need to convey something. I enjoy the fine-tuning, editing, and rewriting….watching this thing grow from a vague idea to a full-formed piece.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to someone starting out on this career path?</h2>
                <p>Work hard. Always. Read everything you can. Learn as much as you can. Connect early with knowledgeable mentors or experts. Don’t make assumptions. Don’t be sloppy. Check your facts. And, remember this: Writers write. Write something every day, even if it’s just a letter home to your parents.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are your favorite things to do in your freetime?</h2>
                <p>What is this “free time” of which you speak? I am unfamiliar with the concept...</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is the biggest risk that you’ve taken along your career path?</h2>
                <p>I moved from a nonprofit to a wire service to a newspaper to a magazine. Each of those transitions came with risks. People think you can’t do something because you’ve always done something else. You have to prove yourself all.the.time. With every assignment. Even when you get to be an old goat.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some accomplishments you are proud of that you tend to highlight on a resume or cover letter?</h2>
                <p>I’ve been very lucky to have a very full and interesting career. I’ve worked with some of the best reporters, writers, and editors in the business. The most pride I can take from a story is when I feel like I connected a reader with an idea. If a reader comes away knowing something more from what I’ve written—and maybe even willing to take action as a result—then I consider that a success.</p>
              </div>
              {/* INTERVIEW WITH BRIDGET */}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Bridget</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your job title?</h2>
                <p>I'm a conservation associate, I work for American Rivers, and in the rivers of the Puget Sound and Columbia River Basin. I primarily work on hydropower projects, like dams, that are used to create electricity. Then the other side of the work that I do is working to get rivers in the Puget Sound and in the Columbia River Basin designated as wild and scenic, which is federal protection. I have a lot of projects and they all fall under either of those two categories.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe the education, training, or experience that prepared you for this career.</h2>
                <p>Overall, I think that what most qualified me for this position as a conservation associate is my volunteer experience. I think that getting involved with some sort of local organization, that you can volunteer with in a higher level capacity is a great way to start getting to where you want to be. And that's not just like going to clean up a river, or planting trees once a month, that's like getting involved on a board, if you can, and being part of the decision making. Staying up to date on local conservation issues is something else that I think was really helpful.</p>
                <p>My associates degree in fisheries science was a big thing. In the organization that I work for, a lot of people are hydrologists or natural resource managers, but I come from a very technical fisheries background. So this organization likes to be diverse in the people that they hire, and I have this unique experience with boots on the ground technical fisheries experience. I do a lot of personal little passion projects that I would work on outside of my volunteer experience, and outside of work. There are a lot of local issues, especially in the Puget Sound, that are very important to me and I just went off and did them on my own. If you don't find someone doing what you want to do, just do it yourself.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>How has the field, topic, and industry changed in the past five years? What do you predict might happen in the next five to 10 years?</h2>
                <p>In the past five years, environmental conservation has seen quite a bit of change, especially  the issues that revolve around the removal or the installment of federal environmental protections. Depending on who gets elected in November, my job could either be a lot easier or a lot harder, and that's frustrating. So, I try to just focus on local issues and those things change based on public interest. What's on the front page of the Seattle Times? The last couple years it's been declining salmon populations and trying to save the southern resident orcas. While those aren't heavily influenced by federal regulation, it definitely plays a part in it. What issue are we rallying around? What has the best communications plan? How are we getting people amped up about this stuff and what issues are most advocated for the time?</p>
                <p>Right now, I feel like we're swinging heavily towards having a lot of issues that are overwhelming to people, and when that happens, a lot of people kind of shut down. We need people to be inspired to keep going, keep working, and continue fighting for these protections that we need in this area. I also hope that people do start to take science a little more seriously instead of having people prey on the emotional aspect of saving wildlife. An example of that for me is saving the orcas. People see these big swimming pandas and they're like, oh, we need to save them. That is really challenging because there are so many other effects that saving salmon populations will have on our area that to me are more important. But I understand that in order to get people to care, you kind of have to target their heartstrings a bit.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some accomplishments, you are proud of that you tend to highlight on a resume or cover letter?</h2>
                <p>I'm really proud of my field work experience. When I was in school, I was working in a hatchery, and I worked on a field team with a tribal cooperative around here, and we did a lot of really laborious field work. Things like beach seining and fyke netting, and a lot of estuarine river work. Before that I worked in a boat yard for like four years, so up until this job I've always had jobs where I'm getting wet, or dirty, or hurt, or tired. So I highlight those because I hope that shows employers that I'm really passionate about what I do, and I'm not just sitting behind a desk faking it. You cannot fake field work, you'll get canned instantly.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to someone starting out on this career path?</h2>
                <p>My first piece of advice is something I wish that someone had told me when I was at UW, which is to not let the opinions of your superiors intimidate you. You might have an idea of what you want to do, which is fantastic, but you are the only person that knows what you want, so listening to the opinions of other people is fruitless. The path that I took to get here had so many switchbacks, it was very confusing, and I got lost, a lot of times, so I would tell someone starting on this career path that it doesn't matter how long it'll take you to get there. Just do what you feel you need to do and take everyone's opinions with a grain of salt. Another piece of advice is just to keep going because marine science environmental conservation is growing more important every day. We're not really catching up as fast as we need to be to compensate for climate change. I want future scientists to know that they're going to be able to contribute to something really powerful. Just Keep Swimming. Avoid burnout by balancing out your life with things that are you're interested in, and if you need to take a day to just sit on the couch and rewatch Parks and Recreation for 17 hours, do it, because then you'll be more refreshed and able to get up and keep working tomorrow.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Science Communication?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Brenna's responses? <a href="https://drive.google.com/file/d/1iEPTIAmAhuqTpi7KIjtSkc_ZHljtdFQA/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Bridget's responses? <a href="https://drive.google.com/file/d/1Eq2BwHp5yfK1PvhEdkxMcDKaurguPVzo/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="http://oceanliteracy.ca/my-journey-into-marine-science-communication-and-how-you-can-do-it-too/" target="_blank" rel="noopener noreferrer">Read more about this person's journey into marine science communication.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.aaas.org/programs/center-public-engagement-science-and-technology/tips-science-journalists" target="_blank" rel="noopener noreferrer">Learn tips directly from top Science Journalists</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.environmentalscience.org/career/environmental-writer" target="_blank" rel="noopener noreferrer">Find out more about what environmental writers do, degree requirements, and salary information.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://podcasts.apple.com/us/podcast/scicomm-is-the-best-and-i-will-tell-you-why/id1010962669?i=1000489184537" target="_blank" rel="noopener noreferrer">Listen to this podcast about science communication and speaking up for the blue.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.nationalgeographic.com/science/phenomena/2013/06/24/a-note-to-beginning-science-writers/#close" target="_blank" rel="noopener noreferrer">Advice for science writers who are starting out.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}