import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class AbrianJaclynYasmin extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Art & Design</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Art & Design"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in art and design. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Art & Design" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in art and design. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/art-and-design" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/art-and-design" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Art & Design",
                "description": "Explore an exciting marine science career in art and design. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/art-and-design"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h1>Art & Design</h1>
              <h2>Meet Abrian</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Abrian+Curington.jpg" alt="abrian curington"/>
                </div>
              </div>
              <p>Illustrator and Cartographer, Abrian Curington, is dedicated to producing graphic novels and fantastical maps that ignite curiosity and champion exploration, ever since graduating from Western Washington University with a BA in Fine Art, and working for several years as a freelance illustrator. She blends her life-long love of science into her work, creating GIS maps, weaving in factual information among my fantasy work, and creating informative travel journals of her trips aboard research vessels as a science communicator.</p>
            </div>
            <div class="marineCareers-header-title-container">
              <h2>Meet Jaclyn and Yasmin</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Jaclyn+and+Yasmin.JPG" alt="jaclyn and yasmin"/>
                </div>
              </div>
              <p>At Fin Pin Shop, we are two biologists who want to share our passion for marine life with you through our products, and we hope you do the same. Some of our products donate to research and ocean conservation. Jaclyn is an avid ocean advocate and ocean lover. She has a Bachelor’s degree from Ohio University in Marine Biology and a Master’s degree from Nova Southeastern University in Environmental Education. Jaclyn is the Expeditions Manager for EarthEcho International.  Yasmin is an artist and ocean advocate. She obtained her Bachelor's degree from the University of Tampa in Biology and a Master's degree in Environmental Science from Drexel University. She believes art plays a powerful role in fostering environmental awareness and creates all our designs with that goal.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW ABRIAN*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Abrian</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of your career.</h2>
                <p>That depends whether I'm at sea, which I am not sadly very often at sea, or on land, but for the most part I open up my book in the morning and see what I get to do today. I'm not always working on the same project, and I'm not always able to do art, at least not right away. Most of the time I wake up, exercise, do some research, take breaks for meals, but it's a lot of research, paperwork, and answering some emails. Then I usually get into some art before the day is done.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some common misconceptions about your job and field?</h2>
                <p>Most people assume that I'm a scientist. I have to make that correction a lot. I am an artist and I have a certificate of GIS cartography, but I'm not actually a scientist. People find that very surprising. A lot of people are surprised that artists are used in science communication. I mean, if you stopped to think about it like, yeah, somebody has to make the stuff look pretty and maybe they think that the scientists do it, which some of them do. But they're just shocked that such a thing even exists.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>How has your field changed over the years?</h2>
                <p>I think things are really starting to broaden. Scientists are looking for new ways to reach people with their work, becoming more open to a variety of mediums and methods. There's a company that specifically contracts artists to make illustrations to go at the front of scientific abstracts. Just to make them more attractive, more appealing and just kind of give a splash of what this thing is about without having to read through this long title. So yeah, things are opening up, the fields are realizing that they work better together, so there's a lot more opportunities for the scientists, sciences, and the arts to join forces, just as they should.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is the biggest risk that you've taken along your career path?</h2>
                <p>I think the biggest risk with the kind of stuff that I do, especially being a freelancer, is putting yourself out there and applying for things, even though you know that you know at least 50% of the time it's going to be no, or not yet, so just continuing to do that by just making the leap. Also, just making the leap into freelance from having a full time day job was a risk. It's kind of like micro risks that build up.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are your favorite things to do in your free time?</h2>
                <p>I'm a Tai Chi instructor. Also, cooking, I garden, and I like to go on easy hikes. They're probably more like nature walks, I don't want to be dying when I get to the top of it, I like just a little bit of challenge. I do a lot of reading when I have a chance, but I'm a recovering workaholic, which is not good for a freelancer. I'm having to make sure I don't work on weekends, or don't work through the evenings, so when I make space for myself I like to read.</p>
              </div>
              {/* INTERVIEW JACYLYN & YASMIN*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Jaclyn & Yasmin</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your job title? Do you feel like that fully describes all that you do?</h2>
                <p>Yasmin:  I’m co-owner of Fin Pin Shop. That title is basically a very big umbrella for all the different things that I do, and a lot of the things that me and Jaclyn do, overlap. So, I do all of the design and the social media marketing, but then we have Jaclyn do the in-person marketing. All the fulfillment ends up at Jaclyn, but I do a lot of the product quality testing and sourcing. So, pretty much from product design all the way to the final product, I'm totally immersed in it and checking to make sure that everything is going good and we're coming out with quality products.</p>
                <p>Jaclyn: I am the other co-owner and I kind of have fallen into more of the administrative stuff. I helped find an accountant to do our taxes, and I do all the shipping, and I also store everything here. So, I'm more on that end of it with the orders and that sort of thing in the business side, but I occasionally come up with good puns, and find weird ocean animals and send them to Yasmin, and then she does her magic, and we have a new thing.</p>
                <p>Yasmin: It's definitely also a creative outlet, not just fulfillment center.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Why should youth want to pursue a career in art and science and why is it important right now?</h2>
                <p>Yasmin: So, whether you're selling your art or you're just creating art for fun, because you love it. Having a message behind it. It makes you feel more fulfilled inside, but it also connects you with so many other people that relate to the shared passion. So whether it's ocean specifically, or land, or whatever, connecting other people about conservation issues is so important for the present and the future. And, "With no blue, no green," as Sylvia Earle says. So that's been something that's driven me and Jaclyn to try as much as we can to make conservation accessible to young people. You can do it, it doesn't matter if you start your business now or in 10 years. You can always start on Instagram and it just started as, don't even need to draw anything just can write, you can do whatever you want and it can manifest into a business leader, that gives back when you're financially able to do so.</p>
                <p>Jaclyn: Youth should want to pursue a career in marine science because our planet depends on the ocean for our health and survival.  Working to protect the big blue is really working to protect us all.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to somebody starting out on this career path?</h2>
                <p>Yasmin: So I need to take my own advice a lot. And when I say this, that patience, you have to be patient, you can work very, very hard, but don't work yourself to the bone without relaxing every now and then., which I need to do, but I need to take my own advice. Just take your time, opportunities will always be there. They will always open up. And trust your gut, if it doesn't feel right, trust that you'll find another avenue. And don't ever feel like you have to do this for anybody else, but yourself. And know that every little thing that you do is so impactful and celebrate the small successes.</p>
                <p>Jaclyn: Try ALL the things!  I jumped around from internship to internship and even job to job in the beginning and throughout my schooling to help me decide not only what I was truly passionate about, but also what I wasn't!  Find your flow and enjoy the ride.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Marine Science and Art?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Abrian's responses? <a href="https://drive.google.com/file/d/1t2jaf_I-ABpS1l7eTO6CNMOHO7i5UPAr/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about FinPinShop's responses? <a href="https://drive.google.com/file/d/1vP2fAUwgpIscYKm8X8Y8FAHyh5slLZga/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://schmidtocean.org/apply/artist-residency-program/" target="_blank" rel="noopener noreferrer">Learn more about the Artist at Sea program on this website.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://mymodernmet.com/ocean-art-world-oceans-day/" target="_blank" rel="noopener noreferrer">Explore six artists who use their art to promote ocean conservation.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.noaa.gov/heritage/stories/meet-artist-conservationist-and-noaa-friend-jim-toomey-creator-of-sherman-s-lagoon" target="_blank" rel="noopener noreferrer">Read about the artist behind Sherman's Lagoon, a world famous newspaper cartoon.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://oceanic.global/artivism/" target="_blank" rel="noopener noreferrer">Join the Artivism Challenge and use art to raise awareness about critical issues impacting our ocean.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.earthecho.org/" target="_blank" rel="noopener noreferrer">Learn about the work that EarthEcho International is doing.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://finpinshop.com/pages/education-corner" target="_blank" rel="noopener noreferrer">Download and color a free activity page from Fin Pin Shop</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://bluecatco.com/sciartresidencies/" target="_blank" rel="noopener noreferrer">Check out SciArt Residencies!</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}