import React from "react";
import StandardContent from "../../../components/blocks/StandardContent";
import Accordion from "../../../components/blocks/Accordion";

const DTETopInlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
    {block.__typename === "contentOptions_standardContent_BlockType" && (
        <StandardContent block={block} />
    )}
    {block.__typename === "contentOptions_accordion_BlockType" && (
        <Accordion block={block} />
    )}
    </React.Fragment>
  ));
};

const DTETopContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <DTETopInlineContent entry={data} />
    </div>
  );
};

export default DTETopContentBlocks;