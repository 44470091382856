import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import { InlineShareButtons } from 'sharethis-reactjs';
// import Breadcrumbs from '../components/breadcrumbs.js';
import Breadcrumbs from '../components/project-breadcrumbs.js';
import ContentBlocks from '../components/ContentBlocks';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';
import Grid from '@material-ui/core/Grid';
import StoryCard from '../components/storyCard';
import Seomatic from '../components/base/seomatic.js';

const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const segment_array = segment_str.split( '/' );
const currentUrl=window.location.href.split('/')
const pathName=currentUrl
const last_segment = segment_array.pop();

//Set Breadcrumb values
const params = [
  { parent: segment_array[1]},
  { pageTitle: last_segment},
  { style: '' }
]

// // Get the preview token from the URL
// let m = document.location.href.match(/\btoken=([^&]+)/);
// let token = m ? m[1] : '';

// // Then forward that on whenever you are sending an API request
// let url = `...?token=${token}`;
// console.log(url);

const QUERY = gql`
  query GetStories($slug: [String]) {
    entry(slug: $slug) {
      sectionHandle
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "F j, Y")
      dateUpdated @formatDateTime(format: "F j, Y")
      author {
        fullName
      }
      url
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        categories {
          title
        }
        themes {
          id
          title
          level
        }
        interestAreas {
          title
          id
        }
        seomatic {
          metaLinkContainer
          metaTagContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
            researchExternalAuthorsPosition
            researchExternalAuthorsBio
            researchExternalAuthorsImage {
              url
            }
            researchExternalAuthorsLink {
              url
              customText
            }
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
            }
          }
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
                emphasizedTitle
                emphasizedSubheadline
                emphasizedOverline
                emphasizedImage {
                    url
                }
                emphasizedImageCaption
                emphasizedContent
                emphasizedLink
                emphasizedBackgroundColor
            }
            ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
            }
            ... on contentOptions_quoteSlideshow_BlockType {
              quoteSliderTitle
              quotes {
                __typename  
                ... on quotes_BlockType {
                  quotesQuote
                  quotesQuoteImage {
                    url
                  }
                  quotesQuoteAttribution
                }
              }
            }
            ... on contentOptions_accordion_BlockType {
              accordionTitle
              accordion {
                __typename  
                ... on accordion_BlockType {
                  accordionItemTitle
                  accordionItemBlurb
                  accordionItemImage {
                    url
                  }
                  accordionItemIcon {
                    url
                  }
                  accordionItemContent
                }
              }
            }
            ... on contentOptions_linkList_BlockType {
              linkListTitle
              linkListList {
                __typename  
                ... on linkListList_BlockType {
                  linkListTitle
                  linkUrl
                  linkListBg {
                    url
                  }
                  linkListHoverTxt
                  linkListTitle
                }
              }
              linkListDisplay
              linkListSize
              columnCount
            }
            ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
            ... on contentOptions_insightsAndUpdates_BlockType {
              insightsAndUpdates {
                title
                slug
                ... on resources_default_Entry {
                  featuredImage {
                    url
                  }
                  title
                  resourceType {
                    title
                  }
                  slug
                  postDate @formatDateTime(format: "F j, Y")
                }
                ... on stories_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  categories {
                    title
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on press_default_Entry {
                  title
                  pressType {
                    title
                  }
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  url
                }
                ... on programs_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on projects_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                  projectType {
                    title
                  }
                }
              }
            }
            ... on contentOptions_newsletterSignup_BlockType {
              id
            }
        }
      }

      ... on resources_default_Entry {
        customTitle
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_newsletterSignup_BlockType {
            id
          }
        }
      }
      ... on programs_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
            }
          }
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_newsletterSignup_BlockType {
            id
          }
        }
      }

      ... on opportunities_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_newsletterSignup_BlockType {
            id
          }
        }
      }

      ... on projects_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        projectType {
          id
          title
        }
        themes {
          id
          title
          level
        }
        interestAreas {
          title
          id
        }
        audiences {
          title
          id
        }
        seomatic {
          metaLinkContainer
          metaTagContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
            researchExternalAuthorsPosition
            researchExternalAuthorsBio
            researchExternalAuthorsImage {
              url
            }
            researchExternalAuthorsLink {
              url
              customText
            }
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              slug
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
            }
          }
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
          ... on contentOptions_slider_BlockType {
            id
            sliderTitle
            slides {
              ... on slides_BlockType {
                id
                slideImage {
                  url
                }
                slideHeadline
                slideContent
                slideLink {
                  customText
                  url
                }
              }
            }
          }
          ... on contentOptions_list_BlockType {
            list {
              ... on list_BlockType {
                id
                listItemImage {
                  url
                }
                listItemHeadline
                listItemRichContent
                listItemLink {
                  url
                  customText
                  target
                }
              }
            }
            listTitle
          }
          ... on contentOptions_linkList_BlockType {
            id
            linkListTitle
            linkListDisplay
            linkListSize
            columnCount
            linkListList {
              ... on linkListList_BlockType {
                id
                linkListBg {
                  url
                }
                linkListTitle
                linkListHoverTxt
                linkUrl
              }
            }
          }
          ... on contentOptions_featuredResource_BlockType {
            id
            featuredResource {
              title
              slug
              ... on resources_default_Entry {
                id
                blurb
                featuredImageCaption
                researchAsset {
                  url
                }
                thumbnailImage {
                  url
                }
              }
            }
          }
          ... on contentOptions_featuredPerson_BlockType {
            id
            featuredPerson {
              ... on teamMembers_default_Entry {
                id
                teamMemberImage {
                  url
                }
                teamMembersPronouns
                teamMemberPosition
                teamMemberBio
              }
              title
              url
            }
          }
          ... on contentOptions_featuredStory_BlockType {
            id
            featuredstory {
              ... on stories_default_Entry {
                id
                slug
                categories {
                  title
                }
                author {
                  fullName
                }
                postDate @formatDateTime(format: "F j, Y")
                researchExternalAuthors {
                  ... on researchExternalAuthors_BlockType {
                    id
                    researchExternalAuthorsAuthor
                  }
                }
                interestAreaTeam {
                  ... on interestAreaTeam_BlockType {
                    id
                    interestAreaTeamMember {
                      title
                    }
                  }
                }
                featuredImage {
                  url
                }
                blurb
                title
              }
              dateCreated @formatDateTime(format: "F j, Y")
            }
          }
          ... on contentOptions_table_BlockType {
            id
            table {
              col1
              col2
              col3
            }
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_newsletterSignup_BlockType {
            id
          }
        }
        statusTag
      }
      ... on press_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        themes {
          title
        }
        pressType {
          title
        }
        interestAreas {
          title
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
                emphasizedTitle
                emphasizedSubheadline
                emphasizedOverline
                emphasizedImage {
                    url
                }
                emphasizedImageCaption
                emphasizedContent
                emphasizedLink
                emphasizedBackgroundColor
            }
            ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
            }
            ... on contentOptions_quoteSlideshow_BlockType {
              quoteSliderTitle
              quotes {
                __typename  
                ... on quotes_BlockType {
                  quotesQuote
                  quotesQuoteImage {
                    url
                  }
                  quotesQuoteAttribution
                }
              }
            }
            ... on contentOptions_accordion_BlockType {
              accordionTitle
              accordion {
                __typename  
                ... on accordion_BlockType {
                  accordionItemTitle
                  accordionItemBlurb
                  accordionItemImage {
                    url
                  }
                  accordionItemIcon {
                    url
                  }
                  accordionItemContent
                }
              }
            }
            ... on contentOptions_linkList_BlockType {
              linkListTitle
              linkListList {
                __typename  
                ... on linkListList_BlockType {
                  linkListTitle
                  linkUrl
                  linkListBg {
                    url
                  }
                  linkListHoverTxt
                  linkListTitle
                }
              }
              linkListDisplay
              linkListSize
              columnCount
            }
            ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
            ... on contentOptions_insightsAndUpdates_BlockType {
              insightsAndUpdates {
                title
                slug
                ... on resources_default_Entry {
                  featuredImage {
                    url
                  }
                  title
                  resourceType {
                    title
                  }
                  slug
                  postDate @formatDateTime(format: "F j, Y")
                }
                ... on stories_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  categories {
                    title
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on press_default_Entry {
                  title
                  pressType {
                    title
                  }
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  url
                }
                ... on programs_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on projects_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                  projectType {
                    title
                  }
                }
              }
            }
            ... on contentOptions_newsletterSignup_BlockType {
              id
            }
        }
      }
    }
  }
`

const ThemeQuery = gql`
  query GetThemesOfStories($slug: [String]) {
    entry(slug: $slug) {
      ... on stories_default_Entry {
        themes {
          id
          title
          level
        }
      }
    }
  }
`

const RelatedPostsQuery = gql`
  query GetRelatedStories($channel: [String], $cat: [QueryArgument]) {
    entries(section: $channel, relatedToCategories: [{ id: $cat }]) {
      title
      slug
      url
      author {
        fullName
      }
      postDate @formatDateTime (format: "M j, Y")
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        categories {
          id
          title
        }
      }
    }
  }
`

const RelatedPosts = (slug) => { 
  const { data:themeData } = useQuery(ThemeQuery, {
    variables: {slug}
  });

  let theme_category_ids = [];
  if (themeData) {
    themeData.entry.themes.map((theme) => {
      if (theme.level > 1) {
        theme_category_ids.push(theme.id);
      }
    })
  }

  const { data, loading, error } = useQuery(RelatedPostsQuery, {
    variables: {
      channel: 'stories',
      // cat: pathName[4] == 'press' ? 691:690
      cat: theme_category_ids
    }
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }
  if (theme_category_ids.length == 0 || data.entries.length == 1) {
    return <div></div>
  }
  let block_count = 0;
  return (
    <div className='relatedPosts'>
      <div className='container'>
        <h2 className='text-large'>Explore Related Programs & Projects</h2>
        <Grid container spacing={4}>
          {data.entries.map(
            (block, index) => {
              if (block.slug != slug.slug && block_count < 3) {
                let props = {
                  block: {block}
                }
                block_count += 1;
                return (
                  <Grid key={index} item sm={12} md={4}>
                    <StoryCard {...props} />
                  </Grid>
                )
              }
            }
          )}
        </Grid>
        <a className="button" href="/programs-projects">Back to All Projects  <i className="fas fa-arrow-right" aria-hidden="true"></i></a>
      </div>
    </div>
  );
}


const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }
  const shareThisStyle = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Helvetica Neue Medium",
    fontSize: "18px"
  };
  console.log("data.entry", data.entry);

  return (
    <div className='entryContent'>
      <div className='container'>
        {data.entry.seomatic && (
          <Seomatic data={data.entry.seomatic} />
        )}
        {(data.entry.projectType || data.entry.statusTag) && (
          <div className='tag-row'>
            {data.entry.projectType && (
              <div className='catLabel strong'>{data.entry.projectType[0].title}</div>
            )}
            {data.entry.statusTag && (
              <div className={'status-tag tag-' + data.entry.statusTag}>
                {data.entry.statusTag == "current" ? "Current" : "Complete"}
              </div>
            )}
          </div>
        )}
        <h1 className='text-xl_bold'>{data.entry.title}</h1>
        {data.entry.blurb && (
            <div className='entryBlurb'
              dangerouslySetInnerHTML={{
              __html: data.entry.blurb,
              }}
            ></div>
        )}
        {/* <p className="text-left text-body_bold textGray">{data.entry.postDateAlt}</p> */}
        {data.entry.featuredImage[0] && (
          <img className='featuredImage' src={data.entry.featuredImage[0].url} alt={data.entry.title} />
        )}
        {data.entry.featuredImageCaption && ( 
          <p className='featuredImageCaption text-half-step'>{data.entry.featuredImageCaption}</p>
        )}

        <ContentBlocks data={data.entry} />

        <div className='projectLineSection'></div>
        <div className='catTags'>
          {data.entry.themes && (       
             data.entry.themes.map((block) => {
               if (block.level > 1) {
                 if (data.entry.sectionHandle == 'projects') {
                   return <a href={"/programs-projects?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
                 } else {
                   return <a href={"/news/press?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
                 }
               }
             })
            )}

          {data.entry.interestAreas && (
            data.entry.interestAreas.map((block) => {
            
                   if(data.entry.sectionHandle == 'projects') {
                return <a href={"/programs-projects?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }else{
                return <a href={"/news/press?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }
            })
          )}
          
          {data.entry.audiences && (
            data.entry.audiences.map((block) => {
              if(data.entry.sectionHandle == 'projects') {
                return <a href={"/programs-projects?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }else{
                return <a href={"/news/press?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }
            })
          )}

          {data.entry.projectType && (
            data.entry.projectType.map((block) => {
              if(data.entry.sectionHandle == 'projects') {
                return <a href={"/programs-projects?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }else{
                return <a href={"/news/press?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }
            })
          )}
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#575857', fontFamily: 'Helvetica Neue Medium', fontSize: '18px' }}>Last Updated: {data.entry.dateUpdated}</div>
          {/* {console.log(data.entry.themes)} */}
          
          <div className="share" style={{ display: 'flex', alignItems: 'center' }}>
            <a className="text-half-step_bold st-custom-button" data-network="sharethis" style={shareThisStyle}>
              <InlineShareButtons
                config={{
                    alignment: 'center',  // alignment of buttons (left, center, right)
                    color: 'social',      // set the color of buttons (social, white)
                    // enabled: true,        // show/hide buttons (true, false)
                    font_size: 20,        // font size for the buttons
                    labels: 'cta',        // button labels (cta, counts, null)
                    language: 'en',       // which language to use (see LANGUAGES)
                    networks: [           // which networks to include (see SHARING NETWORKS)
                        'whatsapp',
                        'linkedin',
                        'messenger',
                        'facebook',
                        'twitter'
                    ],
                    padding: 12,          // padding within buttons (INTEGER)
                    radius: 4,            // the corner radius on each button (INTEGER)
                    show_total: true,
                    size: 40,             // the size of each button (INTEGER)
                    // OPTIONAL PARAMETERS
                }}
              >
              </InlineShareButtons>
              Share This
              &nbsp;
              <img src="/shareIcon.svg" alt="" style={{width: '20px', height: '20px'}}/>
            </a>
          </div>
          </div>        
      </div>
    </div>
  );
}

export default class Project extends Component {
  render() {
    return(
      <div className="pageContainer">
        <Breadcrumbs slug={last_segment} />
        <InlineContent slug={last_segment}/>
        {/* {params[0].parent === "stories" && (
          <RelatedPosts slug={last_segment} />
        )} */}
        {/* <RelatedPosts /> */}
        {/* <GlobalFooter /> */}
        <NewFooter />
      </div>
    );
  }
}