import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './AnnualReport-Research.css';

export default class AnnualReportResearch extends Component {
  render() {
    return(
      <div id="annualReportResearch-container">
        <div class="annualReportResearch-content-container">
          <div class="annualReportResearch-imageDescription-container">
            <div class="annualReportResearch-image-container">
              <img src='https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/FINAL/Research_landingpage-8+final.png' alt='research illustration'/>
            </div>
            <div class="annualReportResearch-description-container">
              <h2>RESEARCH</h2>
              <div class="annualReportResearch-titleLine"></div>
              <p>The Research team designs research studies and collaborates with foundry10 program developers and external partners to examine a range of learning processes and outcomes, including social and cultural development, equity and access, technology and learning, and more. We design our research to examine what creates the most value for youth and grow the public’s understanding of what is possible in learning. In this section, we highlight how we pivoted our research during the pandemic, our work with foundry10 program teams and community partners, and the ways we have shared our work at virtual conferences, in academic journals, and with the communities and participants in our studies.</p>
            </div>
          </div>
          <div class="annualReportResearch-subpages-container">
            <ul id="annualReportResearch-cardsList2" class="annualReportResearch-events-lists2">
                <li>
                  <div class="annualReportResearch-cards-container">
                    <h3>Gap Year White Paper</h3>
                    <p>The research team at foundry10 surveyed over 300 WA State students who graduated high school in 2020 to see how the pandemic impacted their plans to pursue post-secondary education, the challenges and barriers it presented, and their overall feelings about college in the current climate.</p>
                  </div>
                </li>
                <li>
                  <div class="annualReportResearch-cards-container">
                    <h3>Washington State Teacher Technology Use Study</h3>
                    <p>Understanding technology instruction practices can yield a better understanding of existing trends and help identify targets for refining pedagogical practice. This study surveyed 196 Washington State K-12 teachers on their instructional technology use to provide a snapshot of how teachers use technology in a geographical region with relatively high access to educational technology.</p>
                  </div>
                </li>
               <li>
                  <div class="annualReportResearch-cards-container">
                    <h3>Game to Grow</h3>
                    <p>Our researchers are currently conducting a mixed-methods research study examining youth’s experiences with social-emotional learning development after participating in a tabletop gaming program run by Game to Grow— a Seattle-based non-profit organization founded on the belief that games of all kinds have the power to improve people’s lives.</p>
                  </div>
                </li>
            </ul>
            <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#cc886f, #d14e1d)' }} href="/annual-report/2021/research">Learn more</Button>
          </div>
        </div>
      </div>
    );
  }
}