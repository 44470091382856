import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './AnnualReport-Covid.css';

export default class AnnualReportCovid extends Component {
  render() {
    return(
      <div id="annualReportCovid-container">
        <div class="annualReportCovid-content-container">
          <div class="annualReportCovid-mainTitle">
            <h2>Covid-19 Response</h2>
          </div>
          <div class="annualReportCovid-imageDescription-container">
            <div class="annualReportCovid-image-container">
              <img src='https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/FINAL/COVID+PEOPLE+final.png' alt='covid people illustration'/>
            </div>
            <div class="annualReportCovid-description-container">
              <p>As the global pandemic brought incredible change and challenge to many traditional learning spaces, foundry10 adapted to the rapidly shifting needs of our community partners. The multi-faceted structure of our organization allowed us to expand services to a wide range of school communities using a multitude of resources. From STEM activity kits and internet hotspots for distance learning, to rental assistance and grocery store gift cards, to supporting vaccination access programs and families recovering from COVID hospitalization - foundry10’s diverse team of researchers, scientists, artists, and educators cross-pollinated ideas and resources to confront the unique challenges facing local communities.</p>
              <p>While our goals as a philanthropic educational research organization center around expanding the way people think about learning and creating direct value for youth, the pandemic called on us to broaden our approach beyond educational channels of support. We both deepened our existing partnerships and made new connections, enabling us to redirect our resources directly into spaces where we could make the greatest impact.</p>
              <h3>Here are some of the ways our flexible and multi-faceted structure allowed us to respond.</h3>
            </div>
          </div>
          <div class="annualReportCovid-subpages-container">
            <ul id="annualReportCovid-cardsList2" class="annualReportCovid-events-lists2">
              <li>
                  <div class="annualReportCovid-cards-container">
                    <h3>Meeting Basic Needs</h3>
                    <p>By keeping barriers to aid low and listening closely to community connections, we were able to meet the most pressing needs of thousands of families across Washington state. From rental assistance and grocery store gift cards, to utility bills and vision care, we stayed flexible and responsive.</p>
                  </div>
                </li>
                <li>
                  <div class="annualReportCovid-cards-container">
                    <h3>Remote Learning Support</h3>
                    <p>Our classroom experience and relationships with educators positioned us to quickly connect students with reliable internet, support a take-home-book program, develop educational curricula for the YMCA, and more.</p>
                  </div>
                </li>
               <li>
                  <div class="annualReportCovid-cards-container">
                    <h3>Supporting Racial Equity</h3>
                    <p>We took important steps towards delivering on our commitment to BIPOC youth during the pandemic. Partnering with BIPOC community-based organizers allowed us to aid in a way that authentically met real needs by acting in trust with community leaders.</p>
                  </div>
                </li>
            </ul>
            <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#cc886f, #d14e1d)' }} href="/annual-report/2021/covid-19-response">Learn more</Button>
          </div>
        </div>
      </div>
    );
  }
}