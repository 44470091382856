import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
import './everydayHeader.css';

class EverydayHeader extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({isOpen: true})
  }

  render() {
    return (
      <div id="everyday-header-container">
        <div class="everydayVideo-container">
          <video poster="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/Everyday+Unseen+Video+Placeholder+Image.jpg" autoplay="true" muted="true" loop playsinline>
            <source src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/Everyday+Unseen+Header+Video+4MB+Bitrate.mp4" type="video/webm"/>
            <source src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/Everyday+Unseen+Header+Video+4MB+Bitrate.mp4" type="video/mp4"/>
          </video>
        </div>        
        <div id="everydayHeader-content-container">
            <div class="titleText-container">
              <h1>EVERYDAY UNSEEN: YOUTH ART GALLERY</h1>
            </div>
            <div class="modal-video-button">
              <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='32LQVzKRqdM' onClose={() => this.setState({isOpen: false})} />
                <svg onClick={this.openModal} id="everydayHeader-video-icon" x="0px" y="0px" height="100px" width="100px" viewBox="0 0 100 100" enable-background="new 0 0 100 100">
                  <path class="stroke-solid" fill="none" stroke="white"  d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7C97.3,23.7,75.7,2.3,49.9,2.5"/>
                  <path class="stroke-dotted" fill="none" stroke="white"  d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7C97.3,23.7,75.7,2.3,49.9,2.5"/>
                  <path class="icon" fill="white" d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"/>
                </svg>
            </div>
            <div class="everydayHeader-video-button-title">
              <h4>PLAY THE GALLERY VIDEO!</h4>
            </div>
        </div>
      </div>
    )
  }
}

export default EverydayHeader;