import React, { Component } from 'react';
import {
    useQuery,
    gql
  } from "@apollo/client";
import './homeRedesign2023-Research.css';

const researchQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 1, relatedToCategories: [{ id: 689 }]) {
        title
        slug
        url
        author {
          fullName
        }
        postDate @formatDateTime (format: "M j, Y")
        ... on stories_default_Entry {
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          blurb
          featuredImage {
            url
          }  
          categories {
            title
          }
        }
      }
    }
`

const ResearchNews = () => {
    const { data, loading, error } = useQuery(researchQuery);
    if (loading) return null;
    if (error) return `Error! ${error}`;
  
    return (
      data.entries.map(
        (block, index) => {
          return (
            <div className="homeRedesign2023-Research-latest-news-card">
              <a href={'/stories/'+block.slug}>
                <img src={block.featuredImage[0].url} alt="" />
                <div className="homeRedesign2023-Research-latest-news-card-text">
                  <h4 className="homeRedesign2023-Research-latest-news-card-title">{block.title}</h4>
                  {/* NOTE: 'dangerouslySetInnerHTML' is used to render HTML content inside a component */}
                  <p className="homeRedesign2023-Research-latest-news-card-blurb" dangerouslySetInnerHTML={{__html: block.blurb,}}></p>
                  {/* <p className="homeRedesign2023-Research-latest-news-card-blurb">{block.blurb}</p> */}
                  <div className="homeRedesign2023-Research-latest-news-card-date-arrow">
                    <p className="homeRedesign2023-Research-latest-news-card-date">{block.postDate}</p>
                    <p className="homeRedesign2023-Research-latest-news-card-arrow">→</p>
                  </div>
                </div>
              </a>
            </div>
          )
        }
      )
    )
}


export default class HomeResearch extends Component {
  render() {
    return(
      <div id="homeRedesign2023-Research-container">
        <div className="homeRedesign2023-Research-content-container">
            <div className="homeRedesign2023-Research-latest-news">
                <h2>Latest Research News</h2>
                <ResearchNews />
                    {/* <div className="homeRedesign2023-Research-latest-news-card">
                <a href="/stories/family-understanding-of-number-knowledge">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/Latest+Research+News/Family's+Understanding+of+Number+Knowledge.jpg" alt="" />
                        <div className="homeRedesign2023-Research-latest-news-card-text">
                            <p className="homeRedesign2023-Research-latest-news-card-title">New Study: Family's Understanding of Number Knowledge</p>
                            <p className="homeRedesign2023-Research-latest-news-card-blurb">A new foundry10 research study explores the relationship between number words, number gestures, and how caregivers can support this development.</p>
                            <div className="homeRedesign2023-Research-latest-news-card-date-arrow">
                                <p className="homeRedesign2023-Research-latest-news-card-date">November 28th, 2023</p>
                                <p className="homeRedesign2023-Research-latest-news-card-arrow">→</p>
                            </div>
                        </div>
                </a>
                    </div> */}
            </div>
            <div className="homeRedesign2023-Research-labs">
                <h2>Research Labs</h2>
                <div className="homeRedesign2023-Research-labs-tiles">
                    <a className="homeRedesign2023-Research-labs-eachTile" href="/conceptual-development-lab">
                        <div className="homeRedesign2023-Research-labs-tileOne">
                            <h3>Conceptual Development Lab</h3>
                        </div>
                    </a>
                    <a className="homeRedesign2023-Research-labs-eachTile" href="/digital-technologies-and-education-lab">
                        <div className="homeRedesign2023-Research-labs-tileTwo">
                            <h3>Digital Technologies and Education Lab</h3>
                        </div>
                    </a>
                    <a className="homeRedesign2023-Research-labs-eachTile" href="/stem-design-based-research-lab">
                        <div className="homeRedesign2023-Research-labs-tileThree">
                            <h3>STEM Design-Based Research Lab</h3>
                        </div>
                    </a>
                    <a className="homeRedesign2023-Research-labs-eachTile" href="/youth-and-educator-sel-lab">
                        <div className="homeRedesign2023-Research-labs-tileFour">
                            <h3>Youth and Educator SEL Lab</h3>
                        </div>
                    </a>
                </div>
            </div>
        </div>
      </div>
    );
  }
}