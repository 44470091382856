import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class GaryGreene extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Marine Geology</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Marine Geology"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in marine geology. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Marine Geology" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in marine geology. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/marine-geology" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/marine-geology" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Marine Geology",
                "description": "Explore an exciting marine science career in marine geology. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/marine-geology"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h1>Marine Geology</h1>
              <h2>Meet Gary</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Gary+Greene.png" alt="gary greene"/>
                </div>
              </div>
              <p>Dr. H. Gary Greene is a marine geologist/geophysicist specializing in seafloor mapping, geohazards assessment, and marine benthic habitat characterization. Gary obtained his undergraduate degree in paleontology at Long Beach State University, a Master of Science degree in geophysics at San Jose State University, and a PhD in marine geology at Stanford University. He was a research geologist at the U.S. Geological Survey for 28 years after which he retired to become the Director of Moss Landing Marine Labs where he established the Center for Habitat Studies and taught geological oceanography and marine benthic habitat mapping techniques for over 12 years. Presently, Gary is Director of a small research institute on Orcas Island called Tombolo that is partially supported by the SeaDoc Society and private donors, and is a Research Scientist at Friday Harbor Labs of the University of Washington. For the past 30 years Gary has been mapping the seafloor of Alaska, California and the San Juan Archipelago including British Columbia, Canada for the purpose of characterizing benthic habitats, extending land and island geology to the offshore, and assessing marine geohazards such as identifying and mapping submarine landslides, tsunami generation sites, and active faults.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW */}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Gary</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Why should youth want to pursue marine geology?</h2>
                <p>The study of geology is like doing detective investigations, it provides insight into the history of the earth, and understanding how earth processes work allows us to determine how we can live better and sustain good environments. One false innuendo one hears regularly is that we need to save the planet, which really means that we need to save the environment in which our species can survive in as the planet will be around much longer than us. The more we know about the geology, the better we can prepare ourselves to live comfortably on this planet.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to someone starting out on this career path?</h2>
                <p>Do not shy away from taking the basic scientific courses in math, physics, chemistry, and biology as these are all tools used in geology. Also, take a geology course as soon as possible. Geology is a gray science that allows for room to speculate, infer, and guess at things while still being anchored in quantitative analyses, which provides a wide and encompassing field to work in.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your favorite marine science fact? If you could be any marine animal, what would you be?</h2>
                <p>Plate tectonics, and I’m not sure I would want to be a marine animal, I like rocks.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some common misconceptions about your job, and what do people find the most surprising?</h2>
                <p>Always being in the field is a common misconception. People find what is found on the ocean floor and how one gets there to be surprising.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of an ocean mapper.</h2>
                <p>Interpreting data, writing papers, planning surveys, going to sea.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Marine Geology and Ocean Mapping?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Gary's responses? <a href="https://drive.google.com/file/d/1pNu-GwhqGNurByygA1AzLFJvCVA97Xj1/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://medium.com/@lubellwoo/mappin-the-floor-81a3b0472ca4" target="_blank" rel="noopener noreferrer">Read this comic about the team involved in ocean maping from sea.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.thriftbooks.com/w/solving-the-puzzle-under-the-sea-marie-tharp-maps-the-ocean-floor_robert-burleigh/11599763/item/16121053/?mkwid=%7cdc&pcrid=448932070482&pkw=&pmt=&slid=&plc=&pgrid=104532441516&ptaid=pla-957656784437&gclid=EAIaIQobChMI3-mGx9OU6wIVqyCtBh1YTQGdEAQYBSABEgJSyvD_BwE#idiq=16121053&edition=11152098" target="_blank" rel="noopener noreferrer">Learn about the history of ocean mapping from this children's book.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.environmentalscience.org/career/marine-geologist" target="_blank" rel="noopener noreferrer">Find out more general information about how to become a marine geologist.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.youtube.com/playlist?list=PLT_oAYD1nxxmQx78D2FIH5HMKCUEeFgEJ" target="_blank" rel="noopener noreferrer">Learn about the history of the Earth, plate tectonics, and geology from this youtube playlist.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://cmgds.marine.usgs.gov/Program/technology.html" target="_blank" rel="noopener noreferrer">Discover more about the technology involved from this interactive website.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}