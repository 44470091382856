import React from "react";
import StandardContent from "../../../components/blocks/StandardContent";

const DBRTopInlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
    {block.__typename === "contentOptions_standardContent_BlockType" && (
        <StandardContent block={block} />
    )}
    </React.Fragment>
  ));
};

const DBRTopContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <DBRTopInlineContent entry={data} />
    </div>
  );
};

export default DBRTopContentBlocks;