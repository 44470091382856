import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class TanyaRyan extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Ocean Policy</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Ocean Policy"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in ocean policy. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Ocean Policy" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in ocean policy. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/ocean-policy" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/ocean-policy" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Ocean Policy",
                "description": "Explore an exciting marine science career in ocean policy. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/ocean-policy"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h1>Ocean Policy</h1>
              <h2>Meet Tanya</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Tanya+Eison.jpeg" alt="tanya eison"/>
                </div>
              </div>
              <p>Tanya Eison is a proud member of Quinault Indian Nation. She is currently the legislative aide for the nation’s Vice President, Tyson Johnston, and a graduate student at the School of Marine and Environmental Affairs at the University of Washington. Tanya’s work is focused on ocean/climate policy, food sovereignty, and successional planning for future generations of indigenous leaders.</p>
            </div>
            <div class="marineCareers-header-title-container">
              <h2>Meet Ryan</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Ryan+Calkins.jpg" alt="ryan calkins"/>
                </div>
              </div>
              <p>Ryan Calkins was elected to the Port of Seattle Commission in 2017. For more than a decade, Commissioner Calkins ran an import and distribution company in Seattle that was recognized for its industry-leading sustainability initiatives. Prior to his work in business, Commissioner Calkins worked in Central and South America for disaster relief and human rights nonprofits. In his work at the Port, he focuses on sustainable economic development to ensure that the region’s prosperity is shared among all communities. Commissioner Calkins balances current needs with a long-term vision for the Port of Seattle, to ensure that one of our region’s most valuable public assets will continue to thrive for generations to come. He serves on various regional boards including the Puget Sound Regional Council, Economic Development District and Transportation Policy Boards. He lives in Seattle with his wife and three children.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW WITH TANYA*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Tanya</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a Legislative Aide. What do you look forward to most during your workday?</h2>
                <p>A typical day in my position includes an amalgam of researching, drafting policies, monitoring state legislation, and meeting with various folks to gather information to share with my supervisor. I always look forward to sharing my work with my boss to either get my work approved to continue on through the appropriate channels or receive constructive feedback. It's always great to witness my work get implemented too!</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your education, training and/or experience that prepared you for this career.</h2>
                <p>I received a Bachelor's in Environmental Studies from the University of Washington Tacoma and interned at Quinault's Division of Natural Resources for 4 years during that time. I worked in the environmental protection department and was able to focus on fish habitat, forestry, shorebird surveys, restoration, and working with wildlife. I also was afforded many opportunities to take professional level trainings while I interned there. I basically gained a lot of hands on field experience and education during that time to then know how to write and convey information into policies as a legislative aide.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is the biggest risk that you’ve taken along your career path?</h2>
                <p>The biggest risk that I have taken is starting graduate school at the School of Marine and Environmental Affairs at UW. I know that there will be a lot of benefits after I obtain my graduate degree, but I often feel uneasy about stepping away from the career path that I was on with Quinault Indian Nation.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What was your first “win” that made you confident that you were doing the right thing?</h2>
                <p>A departed Quinault tribal elder, Phillip Martin Sr., stopped me outside of my house once to tell me that he was proud of me. He was happy that I had decided to work in natural resources and talked about our identity as salmon and ocean-going people. I respected him dearly and it warmed my heart to know that he was proud of the educational and career choices I was pursuing.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to someone starting out on this career path?</h2>
                <p>I would encourage them to reach out to folks in this field and ask questions. If you want to chat with someone, send them an email! You might get your question answered and then potentially directed to opportunities or information that you didn't know about. You never know!</p>
              </div>
              {/* INTERVIEW WITH RYAN*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Ryan</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a Commissioner at the Port of Seattle.</h2>
                <p>As a Commissioner, my job involves many meetings and a lot of email. In essence, we need to communicate a lot, both listening and sharing. We do so in person (when we aren’t in a COVID lockdown) by meeting with people on a range of topics, and by email and phone when we don’t meet. My favorite part about the job is working with really great people.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some common misconceptions about your job?</h2>
                <p>One common misconception about my job is that we are focused entirely on the seaport. In fact, the biggest part of our job is the airport.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What drew you to this career path?</h2>
                <p>My position is an elected position, and so I had to campaign to win the votes of the majority of the voters in our county to win the seat. Prior to running for election, I worked on someone else’s campaign and really enjoyed it. As someone who has always been interested in and engaged in politics, I was excited to run for office myself.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe the  education, training and/or experience that prepared you for this career.</h2>
                <p>I graduated from high school and then from college. After college I spent a few years living in Latin America, working in disaster recovery and human rights. From there, I went on to graduate school for a Masters in International Relations. I worked in a wholesale distribution company for ten years.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your favorite marine science fact and if you could be any marine animal, what would you be?</h2>
                <p>The ocean contains 38,000 gigatons of carbon, or roughly 16 times the amount of land biospheres. Knowing this reminds us that effective action to address climate change must include efforts in, on, and around the ocean. My favorite marine animal might be the whale shark. I had a profound experience snorkeling alongside one. They are both enormous and gentle.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to someone starting out on this career path?</h2>
                <p>Balance the time you spend working on pursuing your passions and learning new skills. The skills will make you excellent at what you do, and the passions will give you the persistence to see your efforts through to accomplishment.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Ocean Policy?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Tanya's responses? <a href="https://drive.google.com/file/d/1pR_BV3kJBMirjkMjYD5c_U1pwjWbN6lu/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Ryan's responses? <a href="https://drive.google.com/file/d/1-yQTW1dd7hwZUo2ZBuOmyDVJjqrkX5rJ/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="http://qlandandwater.org/" target="_blank" rel="noopener noreferrer">Learn more about the Quinault Devision of Natural Resources.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="http://www.quinaultindiannation.com/" target="_blank" rel="noopener noreferrer">Learn more about the Quinault Indian Nation.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.marinecareers.net/socialpolicy-science" target="_blank" rel="noopener noreferrer">Explore more profiles of real ocean and social policy related professionals.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.portseattle.org/programs/high-school-internships" target="_blank" rel="noopener noreferrer">Port of Seattle High School Internships.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.youthmaritimecollaborative.org/" target="_blank" rel="noopener noreferrer">Explore Youth Maritime Collaborative.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}