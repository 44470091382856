import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import PageHeader from '../components/page-header.js';
import ContentBlocks from '../components/ContentBlocks';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';
//import StoryCard from '../components/storyCard';

const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const segment_array = segment_str.split( '/' );
const lastItem = segment_array[segment_array.length - 1];
const last_segment = segment_array.pop();

const QUERY = gql`
    query GetPage($slug: [String]) {
    entry(slug: $slug) {
      sectionHandle
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "F j, Y")
      author {
        fullName
      }
      url
      ... on pages_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
                emphasizedTitle
                emphasizedSubheadline
                emphasizedOverline
                emphasizedImage {
                    url
                }
                emphasizedImageCaption
                emphasizedContent
                emphasizedLink
                emphasizedBackgroundColor
            }
            ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
            }
            ... on contentOptions_quoteSlideshow_BlockType {
              quoteSliderTitle
              quotes {
                __typename  
                ... on quotes_BlockType {
                  quotesQuote
                  quotesQuoteImage {
                    url
                  }
                  quotesQuoteAttribution
                }
              }
            }
            ... on contentOptions_slider_BlockType {
              sliderTitle
              slides {
                __typename
                ... on slides_BlockType {
                  slideImage {
                    url
                  }
                  slideHeadline
                  slideContent
                  slideLink {
                    customText
                    url
                    target
                  }
                }
              }
            }
            ... on contentOptions_list_BlockType {
              listTitle
              list {
                __typename  
                ... on list_BlockType {
                  listItemHeadline
                  listItemRichContent
                  listItemImage {
                    url
                  }
                  listItemLink {
                    url
                    customText
                    target
                  }
                }
              }
            }
            ... on contentOptions_accordion_BlockType {
              accordionTitle
              accordion {
                __typename  
                ... on accordion_BlockType {
                  accordionItemTitle
                  accordionItemBlurb
                  accordionItemImage {
                    url
                  }
                  accordionItemIcon {
                    url
                  }
                  accordionItemContent
                }
              }
            }
            ... on contentOptions_linkList_BlockType {
              linkListTitle
              linkListList {
                __typename  
                ... on linkListList_BlockType {
                  linkListTitle
                  linkUrl
                  linkListBg {
                    url
                  }
                  linkListHoverTxt
                  linkListTitle
                }
              }
              linkListDisplay
              linkListSize
              columnCount
            }
            ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
            ... on contentOptions_featuredResource_BlockType {
              featuredResource {
                title 
                slug
                ... on resources_default_Entry {
                  blurb
                  researchAsset {
                    url
                  }
                  interestAreaTeam {
                    ... on interestAreaTeam_BlockType {
                      interestAreaTeamMember {
                        title
                        url
                      }
                    }
                  }
                  researchExternalAuthors {
                    ... on researchExternalAuthors_BlockType {
                      researchExternalAuthorsAuthor
                    }
                  }
                  featuredImageCaption
                }
              }
            }
            ... on contentOptions_featuredPerson_BlockType {
              featuredPerson {
                title 
                url
                ... on teamMembers_default_Entry {
                  teamMembersPronouns
                  teamMemberRoles {
                    title
                  }
                  teamMemberImage {
                    url
                  }
                  teamMemberPosition
                  teamMemberBio
                }
              }
            }
            ... on contentOptions_insightsAndUpdates_BlockType {
              insightsAndUpdates {
                title
                slug
                ... on resources_default_Entry {
                  featuredImage {
                    url
                  }
                  title
                  resourceType {
                    title
                  }
                  slug
                  postDate @formatDateTime(format: "F j, Y")
                }
                ... on stories_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  categories {
                    title
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on press_default_Entry {
                  title
                  pressType {
                    title
                  }
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  url
                }
                ... on programs_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on projects_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                  projectType {
                    title
                  }
                }
              }
            }
            ... on contentOptions_featuredStory_BlockType {
              featuredstory {
                title 
                url
                slug
                author {
                  fullName
                }
                postDate @formatDateTime(format: "F j, Y")
                dateCreated @formatDateTime (format: "M j, Y")
                ... on stories_default_Entry {
                  blurb
                  featuredImage {
                    title
                    url
                  }
                  categories {
                    title
                  }
                  themes {
                    title
                  }
                  interestAreas {
                    title
                  }
                  researchExternalAuthors {
                    ... on researchExternalAuthors_BlockType {
                      researchExternalAuthorsAuthor
                    }
                  }
                  interestAreaTeam {
                    ... on interestAreaTeam_BlockType {
                      interestAreaTeamMember {
                        title
                      }
                    }
                  }
                }
              }
            }
        }
      }

      ... on resources_default_Entry {
        customTitle
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
        }
      }
      ... on programs_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
          }
          ... on contentOptions_thirdPartyCode_BlockType {
            thirdPartyCodeTitle
            thirdPartyCodeCode 
          }
          ... on contentOptions_featuredResource_BlockType {
            featuredResource {
              title 
              slug
              ... on resources_default_Entry {
                blurb
                researchAsset {
                  url
                }
                interestAreaTeam {
                  ... on interestAreaTeam_BlockType {
                    interestAreaTeamMember {
                      title
                      url
                    }
                  }
                }
                researchExternalAuthors {
                  ... on researchExternalAuthors_BlockType {
                    researchExternalAuthorsAuthor
                  }
                }
                featuredImageCaption
              }
            }
          }
        }
      }

    }
  }
`

const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  // params = [{ parent: data.entry.sectionHandle }, { pageTitle: data.entry.sectionHandle }, { style: 'dark' }];
  console.log(data);
  return (
    <div className='pageContent'>
      <div className='container'>
       
        <ContentBlocks data={data.entry} />
 
        </div>
       
    </div>
    
  );
}

export default class Page extends Component {
  render() {
    return(
      <div className="pageContainer">
        <PageHeader slug={last_segment} />
        <InlineContent slug={last_segment}/>
        {/* <GlobalFooter /> */}
        <NewFooter />
      </div>
    );
  }
}