import React from "react";
import StandardContent from "./blocks/StandardContent";
import ImageContent from "./blocks/ImageContent";
import FullWidthImage from "./blocks/FullWidthImage";
import Emphasized from "./blocks/Emphasized";
import PullQuote from "./blocks/PullQuote";
import QuoteSlideshow from "./blocks/QuoteSlideshow";
import StandardSlideshow from "./blocks/StandardSlideshow";
import LinkList from "./blocks/LinkList";
import Accordion from "./blocks/Accordion";
import List from "./blocks/List";
import Video from "./blocks/Video";
import FeaturedResource from "./blocks/FeaturedResource";
import FeaturedPerson from "./blocks/FeaturedPerson";
import FeaturedStory from "./blocks/FeaturedStory";
import ThirdPartyCode from "./blocks/ThirdPartyCode";
import ContentTable from "./blocks/ContentTable";
import InsightsAndUpdates from "./blocks/InsightsAndUpdates";
import NewsletterSignup from "./blocks/NewsletterSignup";

const InlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
      {block.__typename === "contentOptions_standardContent_BlockType" && (
        <StandardContent block={block} />
      )}
      {block.__typename === "contentOptions_icp_BlockType" && (
        <ImageContent block={block} />
      )}
      {block.__typename === "contentOptions_fwi_BlockType" && (
        <FullWidthImage block={block} />
      )}
      {block.__typename === "contentOptions_emphasized_BlockType" && (
        <Emphasized block={block} />
      )}
      {block.__typename === "contentOptions_quote_BlockType" && (
        <PullQuote block={block} />
      )}
      {block.__typename === "contentOptions_quoteSlideshow_BlockType" && (
        <QuoteSlideshow block={block} />
      )}
      {block.__typename === "contentOptions_slider_BlockType" && (
        <StandardSlideshow block={block} />
      )}
      {block.__typename === "contentOptions_linkList_BlockType" && (
        <LinkList block={block} />
      )}
      {block.__typename === "contentOptions_accordion_BlockType" && (
        <Accordion block={block} />
      )}
      {block.__typename === "contentOptions_list_BlockType" && (
        <List block={block} />
      )}
      {block.__typename === "contentOptions_featuredResource_BlockType" && (
        <FeaturedResource block={block} />
      )}
      {block.__typename === "contentOptions_featuredPerson_BlockType" && (
        <FeaturedPerson block={block} />
      )}
      {block.__typename === "contentOptions_featuredStory_BlockType" && (
        <FeaturedStory block={block} />
      )}
      {block.__typename === "contentOptions_thirdPartyCode_BlockType" && (
        <ThirdPartyCode block={block} />
      )}
      {block.__typename === "contentOptions_video_BlockType" && (
        <Video block={block} />
      )}
      {block.__typename === "contentOptions_table_BlockType" && (
        <ContentTable block={block} />
      )}
      {block.__typename === "contentOptions_insightsAndUpdates_BlockType" && (
        <InsightsAndUpdates block={block} />
      )}
      {block.__typename === "contentOptions_newsletterSignup_BlockType" && (
        <NewsletterSignup block={block} />
      )}
    </React.Fragment>
  ));
};

const ContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <InlineContent entry={data} />
    </div>
  );
};

export default ContentBlocks;