import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './AnnualReport-Sharing.css';

export default class AnnualReportSharing extends Component {
  render() {
    return(
      <div id="annualReportSharing-container">
        <div class="annualReportSharing-content-container">
          <div class="annualReportSharing-imageDescription-container">
            <div class="annualReportSharing-description-container">
              <h2>SHARING</h2>
              <div class="annualReportSharing-titleLine"></div>
              <p>A key component of our work is taking our discoveries about learning and sharing them with the wider world. How we communicate and connect with teachers, students, researchers, and the broader community furthers our goal of expanding the way people think about learning and creating value for youth. The Sharing Team at foundry10 works to develop a wide array of content in compelling formats. Here are some of our highlights from the past 18 months.</p>
            </div>
            <div class="annualReportSharing-image-container">
              <img src='https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/sharing_1-8.png' alt='sharing illustration'/>
            </div>
          </div>
          <div class="annualReportSharing-subpages-container">
            <ul id="annualReportSharing-cardsList2" class="annualReportSharing-events-lists2">
              {/* <h2>Highlights</h2> */}
                <li>
                  <div class="annualReportSharing-cards-container">
                    <h3>Blog</h3>
                    <p>This year, we re-launched our Medium blog with posts from all of our interest areas.</p>
                  </div>
                </li>
                <li>
                  <div class="annualReportSharing-cards-container">
                    <h3>Web</h3>
                    <p>We have added numerous pages and resources, from our Equity Page to the Ultimate Guide to Underwater Work.</p>
                  </div>
                </li>
               <li>
                  <div class="annualReportSharing-cards-container">
                    <h3>Video</h3>
                    <p>Whether showcasing fun, at-home science activities for kids or exploring careers in hip hop dance, our videos highlight our active approach to learning.</p>
                  </div>
                </li>
            </ul>
            <Button style={{ fontFamily: 'Helvetica Neue Medium', background: '#d14e1d', color: '#ffffff', textTransform: 'none', boxShadow: '1px 1px #b1b1b1', borderRadius: '12px' }} href="/annual-report/2021/sharing">Learn more</Button>
          </div>
        </div>
      </div>
    );
  }
}