import React, { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";

const query = gql`
query GetResource($slug: [String]) {
  entry(slug: $slug) {
    ... on resources_default_Entry {
      title
      breadcrumbTitle
      interestAreaTeam {
        ... on interestAreaTeam_BlockType {
          interestAreaTeamMember {
            title
            ... on teamMembers_default_Entry {
              teamMemberPosition
              teamMembersPronouns
              teamMemberImage {
                url
              }
              teamMemberBio
              teamMemberEmail
            }
          }
        }
      }
    }
    ... on stories_default_Entry {
      title
      breadcrumbTitle
      interestAreaTeam {
        ... on interestAreaTeam_BlockType {
          interestAreaTeamMember {
            title
            ... on teamMembers_default_Entry {
              teamMemberPosition
              teamMembersPronouns
              teamMemberImage {
                url
              }
              teamMemberBio
              teamMemberEmail
            }
          }
        }
      }
    }
    ... on programs_default_Entry {
      title
      breadcrumbTitle
      interestAreaTeam {
        ... on interestAreaTeam_BlockType {
          interestAreaTeamMember {
            title
            ... on teamMembers_default_Entry {
              teamMemberPosition
              teamMembersPronouns
              teamMemberImage {
                url
              }
              teamMemberBio
              teamMemberEmail
            }
          }
        }
      }
    }
    ... on projects_default_Entry {
      title
      breadcrumbTitle
      interestAreaTeam {
        ... on interestAreaTeam_BlockType {
          interestAreaTeamMember {
            title
            ... on teamMembers_default_Entry {
              teamMemberPosition
              teamMembersPronouns
              teamMemberImage {
                url
              }
              teamMemberBio
              teamMemberEmail
            }
          }
        }
      }
    }
    ... on opportunities_default_Entry {
      title
      breadcrumbTitle
      interestAreaTeam {
        ... on interestAreaTeam_BlockType {
          interestAreaTeamMember {
            title
            ... on teamMembers_default_Entry {
              teamMemberPosition
              teamMembersPronouns
              teamMemberImage {
                url
              }
              teamMemberBio
              teamMemberEmail
            }
          }
        }
      }
    }
    ... on press_default_Entry {
      title
      breadcrumbTitle
      interestAreaTeam {
        ... on interestAreaTeam_BlockType {
          interestAreaTeamMember {
            title
            ... on teamMembers_default_Entry {
              teamMemberPosition
              teamMembersPronouns
              teamMemberImage {
                url
              }
              teamMemberBio
              teamMemberEmail
            }
          }
        }
      }
    }
  }
}
`;


const Breadcrumbs = ({ variant = '', slug }) => {
  const { data, loading, error } = useQuery(query, {
    variables: {
      slug: { slug },
    }
  });

  const {
    parent,
    title,
    email,
  } = useMemo(() => {
    if (loading || !data || !data.entry) {
      return { parent: "", title: "", email: "" };
    }

    const entry = data.entry;
    const interestAreaTeamMember = entry?.interestAreaTeam?.[0]?.interestAreaTeamMember?.[0];
    const email = interestAreaTeamMember?.teamMemberEmail || "";

    // Set data from the page url.
    const segment_str = window.location.pathname;
    const segment_array = segment_str.split( '/' );

    return {
      parent: segment_array?.[1] || "",
      title: entry?.breadcrumbTitle || entry?.title || "",
      email: email,
    };
  }, [data, loading, error]);

  const Chevron = () => {
    return (
      <p className="chevron">
        <i className="fa-solid fa-angle-right"></i>
      </p>
    );
  };

  const shareThisStyle = useMemo(
    () => ({
      paddingLeft: "20px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    }),
    []
  );

  return (
    <section className={`globalBreadcrumbs ${variant}`}>
      <div className="container">
        <div className="breadcrumbs-menus">
          <a href="/">
            <i className="fa-solid fa-house" />
          </a>
          {parent && (
            <span style={{ whiteSpace: "nowrap" }}>
              <Chevron />
              <p className="text-medium_bold">
                <a href={"/" + parent}>{parent}</a>
              </p>
            </span>
          )}
          {title && (
            <span style={{ overflow: "hidden" }}>
              <Chevron />
              <h4 className="text-medium_bold">{title}</h4>
            </span>
          )}
        </div>
        <div className="share">

        <a
  href={email ? `mailto:${email}` : "/"}
  style={{
    display: 'inline-block',
    backgroundColor: '#BE5127',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '20px',
    textDecoration: 'none',
    textAlign: 'center',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: '16px',
    fontWeight: 'bold'
  }}
>
  <i className="fa-regular fa-envelope"></i>
  &nbsp;
  Contact Our Team
</a>
          {/* <a
            className="text-half-step_bold st-custom-button"
            data-network="sharethis"
            style={shareThisStyle}
          >
            Share This
            <img
              src="/shareIcon.svg"
              alt=""
              style={{ paddingLeft: "4px" }}
            />
          </a> */}
        </div>
      </div>
    </section>
  );
};

export default Breadcrumbs;