import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import {
  useQuery,
  gql
} from "@apollo/client";

// import ClickAwayListener from '@material-ui/core/ClickAwayListener'; 
import './NavBar.css';

// Craft Data Imports
const navResearchQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 1, relatedToCategories: [{ id: 689 }]) {
        title
        slug
      }
    }
`
const NavResearchNews = () => {
  const { data, loading, error } = useQuery(navResearchQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block, index) => {
        return (
          <div className="researchDropdown-two">
            <h2>LATEST RESEARCH NEWS</h2>
            <p>{block.title}</p>
            <a href={'/stories/'+block.slug} className="researchDropdown-link">Read now<i className="fa-solid fa-arrow-right-long"></i></a>
          </div>
        )
      }
    )
  )
}

const navBlogQuery = gql`
    query blogQuery {
      entries(section: "stories", limit: 1, relatedToCategories: [{ id: 690 }]) {
        title
        slug
        blurb
      }
    }
`

// SIDEBAR STYLED
const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #111111;

  @media screen and (max-width: 500px) {
    margin-left: 2rem;
    font-size: 1.5rem;
    height: 70px;
  }
`;

const SidebarNav = styled.nav`
 display: none;

 @media screen and (max-width: 1100px) {
   background: #ffffff;
   overflow: auto;
   width: 60vw;
   height: 100%;
   max-height: 100%;
   display: flex;
   justify-content: center;
   position: fixed;
   top: 0;
   left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
   transition: 350ms;
   z-index: 10;
   box-shadow: 8px 2px 4px -2px rgba(0,0,0,0.2);
 }

@media screen and (max-width: 500px) {
  width: 90vw;
}
`;

const SidebarWrap = styled.div`
  width: 100%;
`;


function NewNavBar() {
  
  //   SIDEBAR STATE
  const [sidebar, setSidebar] = useState(false);

  const clickAwaySidebar = () => setSidebar(false);

  const openSidebar = () => setSidebar(true);

const closeSidebar = () => setSidebar(false);

const handleLinkClick = (url) => {
  // Perform a full page reload by setting window.location.href to the specified URL
  window.location.href = url;
};

const NavLatestBlog = () => {
  const { data, loading, error } = useQuery(navBlogQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  let rawBlurb = data.entries[0].blurb;
  let textBlurb = rawBlurb.replace(/<[^>]+>/g, '')

  return (
    data.entries.map(
      (block, index) => {
        return (
          <Fragment>
            <h3 className='stories-latest-blog'>Latest Blog</h3>
            <h4 className='stories-latest-blog-title'>{block.title}</h4>
            <p className='stories-latest-blog-text'>{textBlurb}</p>
            <Link to={'/stories/'+block.slug} onClick={() => handleLinkClick('/stories/'+block.slug)} className='stories-latest-blog-read-now'>Read now →</Link>

          </Fragment>
        )
      }
    )
  )
}



  return (
      <nav className='navbar'>
        <div className='navbar-container'>
          <div className="navbar-left-container">
            <Link to='/' className='navbar-logo'>
              <img src="https://f10-image.s3.us-west-2.amazonaws.com/foundry10+website+logo.png" alt="foundry10 logo"></img>
            </Link>
          </div>
          <div className="navbar-right-container">
            <div className='navbar-about'>
              <ul className='nav-menu-about'>
                {/* NOTE: className: nav-links-about-highlight will show f10 orange link for rotating promotion links */}
                {/* <li className='nav-item'> */}
                  {/* HIGHLIGHT LINK START */}
                  {/* NOTE: Use this when there is only one orange highlight link */}
                  {/* <a href='/foundry10-2023-continuing-education-scholarship' className='nav-links-about-highlight'>
                    2023-2024 Continuing Education Scholarship
                  </a> */}
                  {/* HIGHLIGHT LINK END */}
                  
                  {/* HIGHLIGHT LINK DROPDOWN START*/}
                  {/* NOTE: HIGHLIGHT LINK DROPDOWN is for when we need to link more than one orange highlight link */}
                  {/* <div class="nav-highlight-dropdown">
                    <button class="nav-highlight-button">Apply Now: Opportunities for Youth Artists
                      <i className="fa-solid fa-angle-down"></i>
                    </button>
                    <div class="nav-highlight-dropdown-content">
                      <a href='http://tinyurl.com/eu2023ylc' target='_blank' rel="noopener noreferrer">EU Youth Leadership Council Application</a>
                      <a href='https://forms.gle/PzL5n7fbhvFPDA85A' target='_blank' rel="noopener noreferrer">Comics Workshop Application</a>
                    </div>
                  </div> */}
                  {/* HIGHLIGHT LINK DROPDOWN END */}
                {/* </li> */}
                <li className='nav-item'>
                  {/* <Link to='/resources-for-teens' className='nav-links-about'>
                    Resources for Teens
                  </Link> */}
                  <div class="nav-topBar-dropdown">
                    <div class="nav-topBar-button">Resources
                      <i className="fa-solid fa-angle-down"></i>
                    </div>
                    <div class="nav-topBar-dropdown-content">
                      <a href='/resources' id='nav-topBar-resourcesDropdown-highlight'>All Resources and Publications</a>
                      <Link to='/resources-for-educators' className='nav-topBar-resourcesDropdown'>For Educators</Link>
                      <Link to='/resources-for-teens' className='nav-topBar-resourcesDropdown'>For Teens & Young Adults</Link>
                      <Link to='/research' className='nav-topBar-resourcesDropdown'>For Researchers</Link>
                    </div>
                  </div>
                </li>
                <li className='nav-item'>
                  {/* <Link to='https://medium.com/the-foundry10-voice' className='nav-links-about' target="_blank"> */}
                  {/* <Link to={{ pathname: "https://medium.com/the-foundry10-voice" }} className='nav-links-about' target="_blank" rel="noopener noreferrer"> */}
                  {/* OLD STORIES LINK */}
                  {/* <Link to='/stories' className='nav-links-about'>
                    Stories
                  </Link> */}
                  {/* NEW STORIES LINK - FULL RELOAD */}
                  {/* <a href="/stories" onClick={() => handleLinkClick('/stories')} className='nav-links-about'>
                    Stories
                  </a> */}
                  <div class="nav-topBar-dropdown">
                    <div class="nav-topBar-button">Stories
                      <i className="fa-solid fa-angle-down"></i>
                    </div>
                    <div class="nav-topBar-dropdown-content nav-topBar-two-column">
                      <div class="nav-topBar-first-column">
                        <a href="/stories" onClick={() => handleLinkClick('/stories')} id='nav-topBar-resourcesDropdown-highlight'>All Stories</a>
                        <Link to='/stories?cat=690' onClick={() => handleLinkClick('/stories?cat=690')} className='nav-topBar-resourcesDropdown'>Blogs</Link>
                        <Link to='/stories?cat=689' onClick={() => handleLinkClick('/stories?cat=689')} className='nav-topBar-resourcesDropdown'>Research News</Link>
                        <Link to='/stories?cat=35213' onClick={() => handleLinkClick('/stories?cat=35213')} className='nav-topBar-resourcesDropdown'>Philanthropy Stories</Link>
                        <Link to='/stories?cat=691' onClick={() => handleLinkClick('/stories?cat=691')} className='nav-topBar-resourcesDropdown'>Press Releases</Link>
                      </div>
                      <div class="nav-topBar-second-column">
                        <NavLatestBlog />
                      </div>
                    </div>
                  </div>
                </li> 
                <li className='nav-item'>
                  <div class="nav-topBar-dropdown">
                  {/* <Link to='/news' className='nav-links-about'> */}
                    <div class="nav-topBar-button">News/Media
                    <i className="fa-solid fa-angle-down"></i>
                    </div>
                    <div class="nav-topBar-dropdown-content">
                      <a href='/news' id='nav-topBar-resourcesDropdown-highlight'>All News and Media</a>
                      <Link to='/press-resources' className='nav-topBar-resourcesDropdown'>Press Resources</Link>
                      <Link to='/news/newsletters' className='nav-topBar-resourcesDropdown'>Past Newsletters</Link>
                      <Link to='/news/press' className='nav-topBar-resourcesDropdown'>foundry10 in the News</Link>
                    </div>
                  {/* </Link> */}
                  </div>
                </li>
                <li className='nav-item'>
                  {/* <Link to='/resources-for-teens' className='nav-links-about'>
                    Resources for Teens
                  </Link> */}
                  <div class="nav-topBar-about-dropdown">
                    <div class="nav-topBar-about-button">About
                      <i className="fa-solid fa-angle-down"></i>
                      <div class="nav-topBar-about-dropdown-content">
                        <Link to='/what-we-do' className='nav-topBar-resourcesDropdown'>What We Do</Link>
                        <a href='/resources/2023-2024-annual-report-curiosity-as-a-catalyst' className='nav-topBar-resourcesDropdown'>Annual Report</a>
                        <Link to='/equity' className='nav-topBar-resourcesDropdown'>Equity Commitment</Link>
                        {/* <Link to='/about/team' className='nav-topBar-resourcesDropdown'>Our Team</Link> */}
                        <a href='/about/team' className='nav-topBar-resourcesDropdown'>Our Team</a>
                        <Link to='/careers' className='nav-topBar-resourcesDropdown'>Careers</Link>
                        <Link to='/how-we-started' className='nav-topBar-resourcesDropdown'>How We Started</Link>
                        <Link to='/contact' className='nav-topBar-resourcesDropdown'>Contact</Link>
                      </div>
                    </div>
                  </div>
                </li> 
                {/* <li className='nav-item'>
                  <Link to='/equity' className='nav-links-about'>
                    Equity Commitment
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className='navbar-pillars'>
              <ul className="nav-menu-pillars">
                <li className='nav-item'>
                  <a href="/research" onClick={() => handleLinkClick('/research')} className='nav-links-pillars'>
                    Research
                    <i className="fa-solid fa-angle-down"></i>
                  </a>
                  <div className="navbar-researchDropdown">
                    <div className="navbar-researchDropdown-main-container">
                      <div className="navbar-researchDropdown-min-container">
                        <div className="researchDropdown-one">
                          <h2>Research at foundry10</h2>
                          <p>In collaboration with educators, industry professionals, young people, and other researchers, we conduct research to advance understanding of the learning process, support practitioners, and empower learning communities to create value for youth.</p>
                          <Link to='/research' className="researchDropdown-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></Link>
                        </div>
                        <NavResearchNews />
                        {/* <div className="researchDropdown-two">
                          <h2>LATEST RESEARCH NEWS</h2>
                          <p>"Understanding Social Media’s Influence on Teen Well-Being" (2023)</p>
                          <a href='https://www.foundry10.org/resources/understanding-social-medias-influence-on-teen-well-being' className="researchDropdown-link">Read now<i className="fa-solid fa-arrow-right-long"></i></a>
                        </div> */}
                        <div className="researchDropdown-three">
                          <h2>RESEARCH LABS</h2>
                          <ul className="researchDropdown-researchLabs">
                            <li><Link to='/conceptual-development-lab' className="nav-dropdown-listLinks">Conceptual Development Lab</Link></li>
                            <li><Link to= '/digital-technologies-and-education-lab' className="nav-dropdown-listLinks">Digital Technologies and Education Lab</Link></li>
                            <li><Link to='/stem-design-based-research-lab' className="nav-dropdown-listLinks">STEM Design-Based Research Lab</Link></li>
                            <li><Link to='/youth-and-educator-sel-lab' className="nav-dropdown-listLinks">Youth and Educator SEL Lab</Link></li>
                            {/* <li id="nav-dropdown-listLink-temp">Youth Wellness Lab (Coming Soon)</li> */}
                            {/* <li><Link to='/research/group-learning' className="nav-dropdown-listLinks">Group Learning</Link></li>
                            <li><Link to='/research/motivation' className="nav-dropdown-listLinks">Motivation</Link></li>
                            <li><Link to='/research/research-methods' className="nav-dropdown-listLinks">Research Methods</Link></li>
                            <li><Link to='/research/social-and-cultural-development' className="nav-dropdown-listLinks">Social & Cultural Development</Link></li>
                            <li><Link to='/research/technology-and-learning' className="nav-dropdown-listLinks">Technology & Learning</Link></li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className='nav-item'>
                  <a href="/programs" onClick={() => handleLinkClick('/programs')} className='nav-links-pillars'>
                    Programs
                    <i className="fa-solid fa-angle-down"></i>
                  </a>
                  <div className="navbar-programsDropdown">
                    <div className="navbar-programsDropdown-main-container">
                      <div className="navbar-programsDropdown-min-container">
                        <div className="programsDropdown-one">
                          <h2>Programs at foundry10</h2>
                          <p>foundry10 programs provide an opportunity for youth, educators, and teaching artists in the community to come together and learn in new, impactful ways. Our program designs come from collaborative work directly with the people we serve.</p>
                          <Link to='/programs' className="programsDropdown-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></Link>
                        </div>
                        <div className="programsDropdown-two">
                          <h2>FEATURED PROGRAM</h2>
                          {/* <h3>Go Baby Go: Tackling Real-World Problems to Build Excitement in STEM</h3> */}
                          <p>micro:bit Sensors Exploration</p>
                          <a href="/programs-projects/microbit-sensors-exploration" className="programsDropdown-link" target="_blank" rel="noopener noreferrer">Learn more<i className="fa-solid fa-arrow-right-long"></i></a>
                          {/* <Link to='https://medium.com/the-foundry10-voice/everyday-unseen-youth-art-gallery-featured-on-king5-evening-7d5a23925e4b' className="programsDropdown-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></Link> */}
                        </div>
                        {/* <div className="programsDropdown-three">
                          <h2>INTEREST AREAS</h2>
                          <ul className="programsDropdown-programsInterestAreas">
                            <li><Link to='/interest-areas/career-connected-learning-and-life-skills' className="nav-dropdown-listLinks">Career-Connected Learning and Life Skills</Link></li>
                            <li><Link to='/interest-areas/creativity-design-and-play' className="nav-dropdown-listLinks">Creativity, Design, and Play</Link></li>
                            <li><Link to='/interest-areas/health-and-well-being' className="nav-dropdown-listLinks">Health and Well-Being</Link></li>
                            <li><Link to='/interest-areas/technology-media-and-information-literacy' className="nav-dropdown-listLinks">Technology, Media, and Information Literacy</Link></li>
                          </ul>
                          <Link to='/programs' className="programsDropdown-link">View all programming subject areas<i className="fa-solid fa-arrow-right-long"></i></Link>
                        </div> */}
                        <div className="programsDropdown-three">
                          <h2>PARTICIPANT VOICES</h2>
                          {/* <h3>Go Baby Go: Tackling Real-World Problems to Build Excitement in STEM</h3> */}
                          <p>"I used to despise coding, but ... I just [got] so into it during the program! It's quite fun!"<br/>—Student participant, micro:bit Sensors Exploration</p>
                          <a href="/programs-projects/microbit-sensors-exploration" className="programsDropdown-link" target="_blank" rel="noopener noreferrer">Learn more<i className="fa-solid fa-arrow-right-long"></i></a>
                          {/* <Link to='https://medium.com/the-foundry10-voice/everyday-unseen-youth-art-gallery-featured-on-king5-evening-7d5a23925e4b' className="programsDropdown-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className='nav-item'>
                  <a href="/philanthropy" onClick={() => handleLinkClick('/philanthropy')} className='nav-links-pillars'>
                    Philanthropy
                    <i className="fa-solid fa-angle-down"></i>
                  </a>
                  <div className="navbar-philanthropyDropdown">
                    <div className="navbar-philanthropyDropdown-main-container">
                      <div className="navbar-philanthropyDropdown-min-container">
                        <div className="philanthropyDropdown-one">
                          <h2>Philanthropy at foundry10</h2>
                          <p>Our approach to philanthropy is flexible, collaborative, and ethical. We are responsive to the needs of communities and work with them to identify the resources that serve them best. We share lessons learned to inspire and inform others in their work to create value for youth.</p>
                          <Link to='/philanthropy' className="philanthropyDropdown-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></Link>
                        </div>
                        <div className="philanthropyDropdown-two">
                          <h2>FEATURED PROJECT</h2>
                          {/* <h3>Rural Schools Project</h3> */}
                          <p>Recording Studio Opens Up to Young Entrepreneurs Interested In Music</p>
                          <a href='https://www.foundry10.org/stories/recording-studio-opens-up-to-young-entrepreneurs-interested-in-music' className="philanthropyDropdown-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></a>
                        </div>
                        <div className="philanthropyDropdown-three">
                          <h2>FEATURED RESOURCE</h2>
                          {/* <h3>The Benefits and Pitfalls of Organizational Flexibility in Crisis Response</h3> */}
                          <p>Culturally Relevant and Multilingual Book List</p>
                          <a href="/resources/culturally-relevant-and-multilingual-book-list" className="philanthropyDropdown-link" target="_blank" rel="noopener noreferrer">Learn more<i className="fa-solid fa-arrow-right-long"></i></a>
                          {/* <Link to='/philanthropy/the-benefits-and-pitfalls-of-organizational-flexibility-in-crisis-response' className="philanthropyDropdown-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className='nav-item'>
                  <a href="/interest-areas" onClick={() => handleLinkClick('/interest-areas')} className='nav-links-pillars'>
                    Interest Areas
                    <i className="fa-solid fa-angle-down"></i>
                  </a>
                  <div className="navbar-interestareasDropdown">
                    <div className="navbar-interestareasDropdown-main-container">
                      <div className="navbar-interestareasDropdown-min-container">
                        <div className="interestareasDropdown-one">
                          <h2>Interest Areas at foundry10</h2>
                          <p>To maximize our impact, we focus our research, educational programming, and philanthropy on four key areas that shape youth learning. A cross-functional team collaboratively leads our work in each of these areas.</p>
                          <Link to='/interest-areas' className="interestareasDropdown-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></Link>
                        </div>
                        {/* <div className="philanthropyDropdown-two">
                          <h2>FEATURED PROJECT</h2>
                          <h3>Rural Schools Project</h3>
                          <p>Recording Studio Opens Up to Young Entrepreneurs Interested In Music</p>
                          <a href='https://www.foundry10.org/stories/recording-studio-opens-up-to-young-entrepreneurs-interested-in-music' className="philanthropyDropdown-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></a>
                        </div> */}
                        <div className="interestareasDropdown-two">
                          <h2>INTEREST AREAS</h2>
                          <ul className="interestareasDropdown-programsInterestAreas">
                            <li><Link to='/interest-areas/career-connected-learning-and-life-skills' className="nav-dropdown-listLinks">Career-Connected Learning and Life Skills</Link></li>
                            <li><Link to='/interest-areas/creativity-design-and-play' className="nav-dropdown-listLinks">Creativity, Design, and Play</Link></li>
                            <li><Link to='/interest-areas/health-and-well-being' className="nav-dropdown-listLinks">Health and Well-Being</Link></li>
                            <li><Link to='/interest-areas/technology-media-and-information-literacy' className="nav-dropdown-listLinks">Technology, Media, and Information Literacy</Link></li>
                          </ul>
                          <Link to='/interest-areas' className="interestareasDropdown-link">View all Interest Areas<i className="fa-solid fa-arrow-right-long"></i></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div> 
        </div>
        {/* MOBILE NAVBAR START */}
        <div className="mobileNavbar-container">
          <div className="mobileNavbar-left-container">
            <Link to='/' className='mobileNavbar-logo'>
              <img src="https://f10-image.s3.us-west-2.amazonaws.com/foundry10+website+logo.png" alt="foundry10 logo"></img>
            </Link>
          </div>
          {/* <ClickAwayListener onClickAway={clickAwaySidebar}> */}
            <div className="mobileNavbar-right-container">
              <div className="mobileNavbar-navIcon">
                <a><i className="fa-solid fa-bars" aria-label="Open Menu" onClick={openSidebar}></i></a>
              </div>
            </div>
          {/* </ClickAwayListener> */}
        </div>
        {/* <div className="mobileNavbar-container">
          <div className="mobileNavbar-left-container">
            <Link to='/' className='mobileNavbar-logo'>
              <img src="https://f10-image.s3.us-west-2.amazonaws.com/foundry10+website+logo.png" alt="foundry10 logo"></img>
            </Link>
          </div>
          <div className="mobileNavbar-right-container">
            <div className="mobileNavbar-navIcon">
              <a><i className="fa-solid fa-bars" onClick={openSidebar}></i></a>
            </div>
          </div>
        </div> */}
        {/* MOBILE SIDEBAR START */}
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon aria-label="Close Menu" to='#'>
              <i className="fa-solid fa-xmark" onClick={closeSidebar}></i>
            </NavIcon>
            {SidebarData.map((item, index) => {
              // console.log("Sidebar Data", item);
              return <SubMenu item={item} key={index} closeSidebar={closeSidebar} />;
            })}
            {/* TEST SUBMENU */}
            {/* <SubMenu closeSidebar={closeSidebar}/> */}
          </SidebarWrap>
        </SidebarNav>
        {/* <ClickAwayListener onClickAway={clickAwaySidebar}>
          <SidebarNav sidebar={sidebar}>
            <SidebarWrap>
              <NavIcon to='#'>
                <i className="fa-solid fa-xmark" onClick={closeSidebar}></i>
              </NavIcon>
              {SidebarData.map((item, index) => {
                // console.log(item);
                return <SubMenu item={item} key={index} closeSidebar={closeSidebar} />;
              })} */}
              {/* TEST SUBMENU */}
              {/* <SubMenu closeSidebar={closeSidebar}/> */}
            {/* </SidebarWrap>
          </SidebarNav>
        </ClickAwayListener> */}
      </nav>
  );
}

export default NewNavBar;