import React from "react";

const InsightsAndUpdates = ({ block }) => {
  if (block?.insightsAndUpdates?.length > 0) {
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/")[0] + '//' + currentUrl.split("/")[2] + '/';
    return (
      <section className="insightsAndUpdates">
        <div className="container">
          <h3 className="text-xxl_bold text-left emphHeader">
            <span>Insights and Updates</span>
          </h3>
          {block.insightsAndUpdates.map((insightsAndUpdates) => (
            <div className="archiveCard large">
              <div className="imageContainer">
                <img
                  src={insightsAndUpdates.featuredImage[0] ? insightsAndUpdates.featuredImage[0].url : "/news-archive-spacer.png"}
                  alt={insightsAndUpdates.title}
                />
              </div>
              <div className="archiveListContent">
                {insightsAndUpdates.categories?.length > 0 && (
                  <div className="cardCat">
                    <h5>{insightsAndUpdates.categories[0].title}</h5>
                  </div>
                )}
                {insightsAndUpdates.resourceType?.length > 0 && (
                  <div className="cardCat">
                    <h5>{insightsAndUpdates.resourceType[0].title}</h5>
                  </div>
                )}
                {insightsAndUpdates.projectType?.length > 0 && (
                  <div className="cardCat">
                    <h5>{insightsAndUpdates.projectType[0].title}</h5>
                  </div>
                )}
                {insightsAndUpdates.pressType?.length > 0 && (
                  <div className="cardCat">
                    <h5>{insightsAndUpdates.pressType[0].title}</h5>
                  </div>
                )}
                <h3>
                  <a
                    href={
                      domain + (
                        insightsAndUpdates.__typename == "press_default_Entry"
                        ? insightsAndUpdates.url.replace(insightsAndUpdates.url.split("/")[0] + '//' + insightsAndUpdates.url.split("/")[2] + '/', "")
                        : (
                          (
                            insightsAndUpdates.__typename == "projects_default_Entry"
                            ? "programs-projects"
                            : (
                              insightsAndUpdates.__typename == "resources_default_Entry"
                              ? "resources"
                              : (
                                insightsAndUpdates.__typename == "stories_default_Entry"
                                ? "stories"
                                : "projects"
                              )
                            )
                          )
                          + '/' + insightsAndUpdates.slug
                        )
                      )
                    }
                  >
                    {insightsAndUpdates.title}
                  </a>
                </h3>
                <p className="text-body_bold textGray">
                  {insightsAndUpdates.postDate}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
  return null;
};

export default InsightsAndUpdates;