import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './annualReport2021Sharing-Video.css';

export default class AnnualReportSharingVideo extends Component {
  render() {
    return(
      <div id="annualReport2021sharing-video-container">
        <div className="annualReport2021sharing-video-content-container">
                <div className="annualReport2021sharing-video-banner-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/video+bar-8.png" alt="video highlights" />
                    <h2>Video Highlights</h2>
                </div>
            <div className="annualReport2021sharing-video-highlight">
                <h3>
                Beyond Boundaries -- Virtual Edition 
                </h3>
                <a href="https://www.instagram.com/tv/COlRiUEDjdw/?utm_medium=copy_link" target="_blank" rel="noopener noreferrer">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/beyondboundaries.jpeg"  alt="beyond boundaries"/>
                </a>
              <h4>
              Pulling off our annual youth dance event virtually was challenging, but we were determined to show the world how youth keep dancing in the face of the pandemic.  Check out this collection of videos and performances from the event.
              </h4>
            </div>
            <div className="annualReport2021sharing-video-highlight">
                <h3>
                Voices: Everyday Unboxed
                </h3>
                <div className="annualReport2021sharing-video-component-container">
                  <div class="annualReport2021sharing-video-card-container">
                    <ReactPlayer
                    // Everyday Unboxed Virtual Opening Night
                    url='https://www.youtube.com/watch?v=UUM0tly_0Yo'
                    className='annualReport2021sharing-react-player'
                  //   playing
                    controls
                    width='100%'
                    height='100%'
                    />
                  </div>
              </div>
              <h4>
              Our annual youth art gallery also went virtual this year!  We captured the experience in an episode of our series on Voices in education with the hope that what we learned from youth can help others support young artists both during and after the pandemic.
              </h4>
            </div>
        </div>
      </div>
    );
  }
}

